import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';

function useMatchResult(props) {
  const { matchId = '' } = props;
  const dispatch = useDispatch();
  const {
    loading,
    matchInfo,
    matches,
  } = useSelector((state) => ({
    loading: state.matchesLoading,
    matchInfo: state.matchSelected || {},
    matches: state.matches || [],
  }));

  useEffect(()=> {
    if (matches?.length && matchId) {
      dispatch(action.selectMatch({ matchId: matchId }));
    }
  }, [matches, matchId, dispatch]);

  return {
    loading,
    matchInfo,
  }
}

export default useMatchResult;