import styled from 'styled-components';

export const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #27262c;
  border-bottom: 1px solid #383241;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

export const LeftCol = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const MenuList = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px 0;
`;

export const MenuItem = styled.div`
  font-family: 'Arial Rounded MT Bold';
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 26px;
  font-size: 18px;
  color: ${props => props.active ? `#5FC3D1` : '#fff'};
  height: 100%;
  &:hover {
    background-color: #353547;
    border-radius: 16px;
  }
`;

export const RightCol = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  color: #FFFFFF;
`;
