import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { formatInTimeZone } from 'date-fns-tz'
import { formatDistanceToNow } from 'date-fns';
import * as action from 'actions';

function useDate() {
  const dispatch = useDispatch();
  const {
    timeZone,
  } = useSelector((state) => ({
    timeZone: state.timeZone || '',
  }));

  const formatDate = useCallback((date, format)=> {
    if (date) {
      return formatInTimeZone(date, timeZone, format);
    }
    return '';
  }, [timeZone]);

  const onChangeTimezone = useCallback((_timezone = '')=> {
    dispatch({
      type: action.SELECT_TIMEZONE,
      payload: _timezone
    })
  }, [dispatch]);

  return {
    formatDate,
    timeZone,
    onChangeTimezone,
    getTimeFromNow: formatDistanceToNow,
  }
}

export default useDate;