import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import * as action from 'actions';

function useInitApp() {
  let location = useLocation();
  const dispatch = useDispatch();
  const {
    loading,
    matches,
  } = useSelector((state) => ({
    loading: state.matchesLoading || false,
    matches: state.matches || [],
  }));

  useEffect(()=> {
    dispatch(action.getMatches());
  }, [dispatch]);

  useEffect(()=> {
    if (matches?.length && location?.pathname?.includes('/detail')) {
      const matchId = location?.pathname?.split('/')?.[2] || '';
      dispatch(action.selectMatch({ matchId: matchId }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, dispatch]);

  return {
    loading,
    matches,
  }
}

export default useInitApp;