import React, { memo, useState } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import { useLocalStorage } from 'react-use';

function PolicyPopup(props) {
  const [isReadPolicy, setIsReadPolicy] = useLocalStorage('isReadPolicy', false);
  const [show, setShow] = useState(!isReadPolicy);
  const onClose = () => setShow((show) => !show);
  const onAgree = () => { setIsReadPolicy(true); setShow(false) };
  const onExit = () => { window.location.href = 'https://whitepaper.tingbet.io/support/terms-of-service' }

  return (
    <Modal
      visible={show}
      title={<Label>Policy confirmation</Label>}
      onCancel={onExit}
      footer={null}
      width={'546px'}
    >
      <Box flex={{ direction: 'column', rowGap: '40px' }}>
        <Label size={16} block>
          YOU MUST BE OVER 21 AND AGREE TO THE TERMS BELOW BEFORE CONTINUING:
        </Label>
        <Box bgColor="#1E1D20" p="10px 20px">
          <Label size={14}>
            This website contains information, links, images of crypto investment via the football match consensus process. <br /><br />
            This website is not actually a gamble platform. However, to protect your crypto asset, and support you to maximize your profit, you should aware this term. <br /><br />
            The maximum investing is 5 BNB.
          </Label>
        </Box>
        <Box grid={{ columns: '1fr 1fr', columnGap: '26px' }}>
          <Button height={'30px'} p="13px 0" style={{ flexGrow: 1 }} onClick={onExit}>EXIT</Button>
          <Button height={'30px'} p="13px 0" type='primary' style={{ flexGrow: 1 }} onClick={onAgree}>I AGREE</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(PolicyPopup);