// core
import React from 'react';
import { Routes, Route, Outlet, Link } from 'react-router-dom';

// internal
import Header from './components/Header';
import handleSnackbar from './components/Snackbar';
import ModalNotification from './components/ModalNotification';
import { getCookie } from './utils/cookies';
import Layout from 'components/Layout';
import MatchList from 'containers/MatchList';
import MatchDetail from 'containers/MatchDetail';
import MatchBet from 'containers/MatchBet';
import MatchResult from 'containers/MatchResult';
import BetPoolsHistory from 'containers/BetPoolsHistory';
import useIntApp from 'hooks/useInitApp';
import { getTimezoneOffset } from 'utils/common';
import { GMT_OPTIONS } from 'constants/index';
import useDate from 'hooks/useDate';
import './App.css';

// external
import { useDispatch, useSelector } from 'react-redux';
import {} from 'dotenv/config';

import * as actions from './actions';

function App() {
  useIntApp();
  const dispatch = useDispatch();
  const { onChangeTimezone } = useDate();
  const {
    balance,
    account,
    contractAddress,
    userEmail,
    notification,
    loading,
    statusBuyingTingSeed,
  } = useSelector((state) => ({
    balance: state.balance,
    account: state.account,
    contractAddress: state.contractAddress,
    userEmail: state.userEmail,
    notification: state.notification,
    loading: state.loading,
    statusBuyingTingSeed: state.statusBuyingTingSeed,
  }));

  React.useEffect(() => {
    dispatch(actions.web3Connect());
  }, []);

  React.useEffect(async () => {
    if (notification.status && notification.title && notification.message) {
      handleSnackbar({ ...notification, dispatch });
    }
  }, [notification.status, notification.title, notification.message]);

  React.useEffect(() => {
    const currentTimezoneOffset = getTimezoneOffset();
    const currentTimezone = GMT_OPTIONS.find(
      (item) => item?.offset === currentTimezoneOffset
    );
    onChangeTimezone(currentTimezone?.value);
  }, []);

  const userToken = getCookie('tingee-token');
  const userEmailCookie = getCookie('tingee-user-email');
  const currentUserEmail = userEmail || userEmailCookie;

  const readyFunding = contractAddress && account;

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MatchList />} key={'list'} />
          <Route path="pool/:id" element={<MatchBet />} key={'detail-bet'} />
          <Route
            path="detail/:matchId"
            element={<MatchDetail />}
            key={'detail'}
          />
          <Route
            path="result/:matchId"
            element={<MatchResult />}
            key={'result'}
          />
          <Route
            path="history/:id"
            element={<BetPoolsHistory />}
            key={'result'}
          />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
