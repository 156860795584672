import React, { memo } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';

function CustomerSupportPopup(props) {
  const { show = false, onClose = () => {} } = props;
  return (
    <Modal
      visible={show}
      title={'Customer Support'}
      onCancel={onClose}
      footer={null}
      width={'50%'}
    >
      <Box flex={{ direction: 'column', rowGap: '200px' }}>
        <Label size={16} block>
          You can contact us via these platforms: <br /><br />

          Telegram: <br />
          Email: support@tingbet.io <br />
          Discord <br />
        </Label>
        <Button onClick={onClose} fullWidth p="8px 0" type='outline' fontSize={22}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default memo(CustomerSupportPopup);