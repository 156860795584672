import Web3 from 'web3';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { BscConnector } from '@binance-chain/bsc-connector';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { WALLET_TYPE } from 'constants/wallet';
import { de } from 'date-fns/locale';
import { isMobile } from 'react-device-detect';

// const ConnectToInjected = async () => {
//   let provider = null;
//   debugger;
//   if (typeof window.ethereum !== 'undefined') {
//     provider = window.ethereum;
//     try {
//       await provider.request({ method: 'eth_requestAccounts' });
//     } catch (error) {
//       throw new Error('User Rejected');
//     }
//   } else if (window.web3) {
//     provider = window.web3.currentProvider;
//   } else if (window.celo) {
//     provider = window.celo;
//   } else {
//     throw new Error('No Web3 Provider found');
//   }
//   return provider;
// };

export const checkInstalledWallet = (type) => {
  if (type == WALLET_TYPE?.INJECTED?.id) {
    return window?.ethereum || window?.web3;
  } else if (type == WALLET_TYPE?.TRUST?.id) {
    return (
      window?.ethereum?.isTrust ||
      window?.ethereum?.isTrustWallet ||
      window?.ethereum?.isTrustBrowser
    );
  } else if (type == WALLET_TYPE?.METAMASK?.id) {
    return window?.ethereum?.isMetaMask;
  } else if (type == WALLET_TYPE?.BRAVE?.id) {
    return window?.ethereum?.isBraveWallet;
  } else if (type == WALLET_TYPE?.OPERA?.id) {
    return window?.ethereum?.isOpera;
  } else if (type == WALLET_TYPE?.COINBASE?.id) {
    return window?.ethereum?.isCoinBase || window?.ethereum?.isCoinbaseWallet;
  } else if (type == WALLET_TYPE?.BINANCE?.id) {
    return window?.ethereum?.BinanceChain;
  }
  return null;
};

export const getWeb3 = async (type) => {
  if (
    window.ethereum ||
    type == WALLET_TYPE?.BINANCE?.id ||
    type == WALLET_TYPE?.COINBASE?.id ||
    type == WALLET_TYPE?.WALLETCONNECT?.id
  ) {
    let provider;
    try {
      // TODO: refactor
      const localWalletKeys = Object.keys(localStorage).filter(
        (x) => !['isReadPolicy', 'isSigned', 'wallet_type'].includes(x)
      );
      localWalletKeys.forEach((k) => localStorage.removeItem(k));

      if (type == WALLET_TYPE?.BINANCE?.id) {
        if (!window.BinanceChain) return null;
        provider = window.BinanceChain;
      } else if (type == WALLET_TYPE?.COINBASE?.id) {
        const coinbaseWallet = new CoinbaseWalletSDK({
          overrideIsMetaMask: false,
        });
        console.log('70:', coinbaseWallet.getQrUrl());
        provider = coinbaseWallet.makeWeb3Provider(
          process.env.REACT_APP_SMARTCHAIN_URL,
          process.env.REACT_APP_SMARTCHAIN_ID
        );
        console.log('70:', provider);
      } else if (type == WALLET_TYPE?.WALLETCONNECT?.id) {
        const providerOptions = {
          walletconnect: {
            package: WalletConnectProvider, // required
            options: {
              infuraId: process.env.REACT_APP_INFURA_ID,
            },
          },
        };
        providerOptions.walletconnect.options[
          process.env.REACT_APP_SMARTCHAIN_ID
        ] = process.env.REACT_APP_SMARTCHAIN_URL;

        window.localStorage.removeItem('walletconnect');
        const web3Modal = new Web3Modal({
          // network: 'mainnet', // optional
          cacheProvider: false, // optional
          disableInjectedProvider: false,
          providerOptions, // required
        });
        await web3Modal.clearCachedProvider();
        provider = await web3Modal.connectTo('walletconnect');
      } else if (window.celo) {
        provider = window.celo;
      } else {
        provider = window.ethereum;
      }
    } catch (error) {
      console.log('Can not connect web3', error);
      return null;
    }
    if (!provider) return null;

    try {
      provider.on('accountsChanged', function (accounts) {
        window.location.reload(false);
      });

      provider.on('networkChanged', function (accounts) {
        window.location.reload(false);
      });

      await provider.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      console.log('User Rejected', error);
      return null;
    }
    return new Web3(provider);
  } else if (window.web3) {
    console.log('Injected web3 detected.');
    await window.web3.currentProvider.enable();
    await window.web3.currentProvider.request({
      method: 'eth_requestAccounts',
    });
    window.web3.currentProvider.on('accountsChanged', function (accounts) {
      window.location.reload(false);
    });

    window.web3.currentProvider.on('networkChanged', function (accounts) {
      window.location.reload(false);
    });
    return window.web3;
  }
  return null;
  // else {
  //   // Fallback to localhost; use dev console port by default...
  //   const provider = new Web3.providers.HttpProvider(
  //     process.env.REACT_APP_SMARTCHAIN_URL
  //   );
  //   const web3 = new Web3(provider);

  //   if (!web3) {
  //     console.log('No web3 instance injected, using Local web3.');
  //     return null;
  //   }
  //   if (
  //     web3.currentProvider.connection.networkVersion !==
  //     process.env.REACT_APP_SMARTCHAIN_ID
  //   ) {
  //     console.log('Unknown network, please change network to BSC network');
  //     return null;
  //   }

  //   return web3;
  // }

  // window.localStorage.removeItem('walletconnect');
  // const web3Modal = new Web3Modal({
  //   // network: 'mainnet', // optional
  //   cacheProvider: false, // optional
  //   disableInjectedProvider: false,
  //   providerOptions, // required
  // });
  // await web3Modal.clearCachedProvider();
  // try {
  //   // const provider4 = await web3Modal.connectTo('walletconnect');
  //   // const provider4 = await web3Modal.connect();
  //   const provider4 = window.ethereum;
  //   provider4.on('accountsChanged', function (accounts) {
  //     window.location.reload(false);
  //   });

  //   provider4.on('networkChanged', function (accounts) {
  //     window.location.reload(false);
  //   });

  //   return new Web3(provider4);

  //   // https://github.com/WalletConnect/web3modal/tree/master/src/providers/connectors
  //   // const check_bn = await window.BinanceChain.isConnected(); // binance
  //   // if (check_bn) {
  //   //   const provider = window.BinanceChain;
  //   //   try {
  //   //     await provider.request({ method: 'eth_requestAccounts' });
  //   //   } catch (error) {
  //   //     throw new Error('User Rejected');
  //   //   }
  //   //   return new Web3(provider);
  //   // }

  //   // for coinbase
  //   // const coinbaseWallet = new CoinbaseWalletSDK({
  //   //   overrideIsMetaMask: false,
  //   // });

  //   // const provider2 = coinbaseWallet.makeWeb3Provider(
  //   //   process.env.REACT_APP_SMARTCHAIN_URL,
  //   //   process.env.REACT_APP_SMARTCHAIN_ID
  //   // );
  //   // await provider2.send('eth_requestAccounts');

  //   // return new Web3(provider2);

  //   const provider1 = await ConnectToInjected(); // metamask, trust
  //   return new Web3(provider1);
  //   // debugger;
  //   // const provider = await web3Modal.connectTo('metamask');
  //   // const provider = await web3Modal.connectTo('metamask');
  //   const provider = await web3Modal.providerController.connectTo(
  //     'coinbasewallet'
  //   );
  //   // provider.on('accountsChanged', function (accounts) {
  //   //   window.location.reload(false);
  //   // });

  //   // provider.on('networkChanged', function (accounts) {
  //   //   window.location.reload(false);
  //   // });
  //   const web3 = new Web3(provider);
  //   return web3;
  // } catch (ex) {
  //   console.log('56:', ex);
  //   return null;
  // }

  // return;

  // if (!window.ethereum || typeof window.ethereum === 'undefined') {
  //   return 'not_install';
  // }
  // // Wait for loading completion to avoid race conditions with web3 injection timing.
  // if (false && window.ethereum) {
  //   // const web3 = new Web3(window.ethereum);
  //   try {
  //     let provider;
  //     debugger;
  //     // isTrustWallet
  //     // isCoinbaseWallet
  //     // isMetaMask
  //     if (!window.ethereum.providers || window.ethereum.providers.length == 1) {
  //       provider = window.ethereum;
  //     } else {
  //       // https://github.com/weavrdao/weavr-app/blob/8936829c5cd62000596c76b194836ec189d58a30/src/data/network/web3/ethereum/ethereumClient.js
  //       // const providerCB = window.ethereum.providers.find(
  //       //   (x) => x.isCoinbaseWallet
  //       // );

  //       const providerDefault = window.ethereum.providers.find(
  //         (x) => !x.isCoinbaseWallet
  //       );

  //       const checkConnected = await providerDefault.isConnected();
  //       if (providerDefault && checkConnected) {
  //         provider = providerDefault;
  //       } else {
  //         const coinbaseWallet = new CoinbaseWalletSDK({
  //           overrideIsMetaMask: false,
  //         });

  //         provider = coinbaseWallet.makeWeb3Provider(
  //           process.env.REACT_APP_SMARTCHAIN_URL,
  //           process.env.REACT_APP_SMARTCHAIN_ID
  //         );
  //         await provider.send('eth_requestAccounts');
  //       }
  //     }

  //     const web3 = new Web3(provider);
  //     await provider.enable();
  //     // Request account access if needed
  //     // await window.ethereum.enable();
  //     // Acccounts now exposed

  //     provider.on('accountsChanged', function (accounts) {
  //       window.location.reload(false);
  //     });

  //     provider.on('networkChanged', function (accounts) {
  //       window.location.reload(false);
  //     });

  //     return web3;
  //   } catch (error) {
  //     console.error('Web3Error:', error);
  //   }
  // }
  // Legacy dapp browsers...
};

export const getEtherEnv = () => {
  console.log(window.navigator.userAgent);
  if (!window.ethereum) return 'none';
  return Object.keys(window.ethereum);
};

export const getProviderName = () => {
  if (!window.ethereum) return 'none';
  return {
    isMetaMask: window.ethereum.isMetaMask,
    isMetaMaskWallet: window.ethereum.isMetaMaskWallet,
    isMetaMaskBrowser: window.ethereum.isMetaMaskBrowser,
    isCoinBase: window.ethereum.isCoinBase,
    isCoinbase: window.ethereum.isCoinbase,
    isCoinbaseBrowser: window.ethereum.isCoinbaseBrowser,
    isCoinbaseWallet: window.ethereum.isCoinbaseWallet,
    isBinance: !!window.BinanceChain,
    isTrust: window.ethereum.isTrust,
    isTrustWallet: window.ethereum.isTrustWallet,
    isTrustBrowser: window.ethereum.isTrustBrowser,
    isOpera: window.ethereum.isOpera,
    isOperaWallet: window.ethereum.isOperaWallet,
    isOperaBrowser: window.ethereum.isOperaBrowser,
    isBrave: window.ethereum.isBrave,
    isBraveWallet: window.ethereum.isBraveWallet,
    isBraveBrowser: window.ethereum.isBraveBrowser,
  };
};

export const getListOfWallets = () => {
  if (window.ethereum) {
    if (
      window.ethereum.isBraveBrowser ||
      (window.ethereum.isBraveWallet && isMobile())
    ) {
      return [WALLET_TYPE.BRAVE, WALLET_TYPE.INJECTED];
    } else if (window.ethereum.isBraveWallet) {
      return [
        WALLET_TYPE.BRAVE,
        WALLET_TYPE.METAMASK,
        WALLET_TYPE.COINBASE,
        WALLET_TYPE.TRUST,
        WALLET_TYPE.WALLETCONNECT,
        WALLET_TYPE.BINANCE,
        WALLET_TYPE.INJECTED,
        WALLET_TYPE.OPERA,
      ];
    } else if (window.ethereum.isMetaMask) {
      return [
        WALLET_TYPE.METAMASK,
        WALLET_TYPE.COINBASE,
        WALLET_TYPE.WALLETCONNECT,
        WALLET_TYPE.BINANCE,
        WALLET_TYPE.INJECTED,
        WALLET_TYPE.TRUST,
        WALLET_TYPE.OPERA,
        WALLET_TYPE.BRAVE,
      ];
    } else if (
      window.ethereum.isMetaMaskBrowser ||
      window.navigator.userAgent.toLocaleLowerCase().indexOf('metamask') > 0
    ) {
      return [WALLET_TYPE.METAMASK, WALLET_TYPE.INJECTED];
    } else if (window.ethereum.isCoinbaseWallet) {
      return [
        WALLET_TYPE.COINBASE,
        WALLET_TYPE.METAMASK,
        WALLET_TYPE.TRUST,
        WALLET_TYPE.WALLETCONNECT,
        WALLET_TYPE.BINANCE,
        WALLET_TYPE.INJECTED,
        WALLET_TYPE.OPERA,
        WALLET_TYPE.BRAVE,
      ];
    } else if (window.ethereum.isCoinbaseBrowser) {
      return [WALLET_TYPE.COINBASE, WALLET_TYPE.INJECTED];
    } else if (
      window.ethereum.isTrustBrowser ||
      (window.ethereum.isTrust && !window.ethereum.isTrustWallet)
    ) {
      return [WALLET_TYPE.TRUST, WALLET_TYPE.INJECTED];
    } else if (window.ethereum.isTrustWallet) {
      return [
        WALLET_TYPE.TRUST,
        WALLET_TYPE.METAMASK,
        WALLET_TYPE.COINBASE,
        WALLET_TYPE.WALLETCONNECT,
        WALLET_TYPE.BINANCE,
        WALLET_TYPE.INJECTED,
        WALLET_TYPE.OPERA,
        WALLET_TYPE.BRAVE,
      ];
    } else if (window.ethereum.isOperaBrowser || window.ethereum.isOpera) {
      return [
        WALLET_TYPE.OPERA,
        WALLET_TYPE.INJECTED,
        WALLET_TYPE.COINBASE,
        WALLET_TYPE.BINANCE,
        WALLET_TYPE.WALLETCONNECT,
      ];
    } else {
      return [
        WALLET_TYPE.METAMASK,
        WALLET_TYPE.COINBASE,
        WALLET_TYPE.TRUST,
        WALLET_TYPE.WALLETCONNECT,
        WALLET_TYPE.BINANCE,
        WALLET_TYPE.INJECTED,
        WALLET_TYPE.OPERA,
        WALLET_TYPE.BRAVE,
      ];
    }
  } else if (window.BinanceChain) {
    return [
      WALLET_TYPE.BINANCE,
      WALLET_TYPE.METAMASK,
      WALLET_TYPE.COINBASE,
      WALLET_TYPE.TRUST,
      WALLET_TYPE.WALLETCONNECT,
      WALLET_TYPE.INJECTED,
      WALLET_TYPE.OPERA,
      WALLET_TYPE.BRAVE,
    ];
  } else {
    return [
      WALLET_TYPE.METAMASK,
      WALLET_TYPE.COINBASE,
      WALLET_TYPE.TRUST,
      WALLET_TYPE.WALLETCONNECT,
      WALLET_TYPE.BINANCE,
      WALLET_TYPE.INJECTED,
      WALLET_TYPE.OPERA,
      WALLET_TYPE.BRAVE,
    ];
  }
};
// export default getWeb3;
// export default getCurrentWallet;
