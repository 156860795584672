import React, { memo, useState, useEffect } from 'react';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import { Tab } from './styles';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import Wallet from './Wallet';
import Yourbets from './Yourbets';
import Yourpool from './Yourpool';
import COLOR from 'constants/color';
import useUser from 'hooks/useUser';
import * as actions from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import usePlatform from 'hooks/usePlatform';

function UserProfile(props) {
  const dispatch = useDispatch();
  const { isLessThanTablet } = usePlatform();
  // const [showUser, setShowUser] = useState(false);
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState('wallet');
  const {
    isLoggedIn,
    // currentUserEmail,
    // userToken,
    balance,
    contractAddress,
    tingCoin,
    onLogout,
    onGetUserInfo,
  } = useUser();

  useEffect(() => {
    if (modal) {
      onGetUserInfo();
    }
  }, [modal]);

  // const fetWeb3Init = async () => {
  //   try {
  //     await dispatch(actions.web3Connect());
  //     await dispatch(actions.instantiateContracts());
  //     setShowUser(true);
  //     setTab('wallet');
  //   } catch (err) {
  //     console.log('err', err);
  //   }
  // };

  const openChooseWallet = async () => {
    await dispatch(actions.openWallet());
  };

  const { account } = useSelector((state) => ({
    account: state.account,
  }));
  return (
    <>
      <Modal
        visible={modal}
        title={'Your Wallet'}
        footer={null}
        onCancel={() => setModal(false)}
        bodyStyle={{ padding: 0 }}
        width={isLessThanTablet ? '100%' : '820px'}
        style={{ top: isLessThanTablet ? '50px' : '100px' }}
      >
        {isLoggedIn && (
          <>
            <Box
              width={'100%'}
              height="57px"
              bgColor={COLOR.bgTabWallet}
              flexCenter
            >
              <Box
                width={isLessThanTablet ? '100%' : '95%'}
                bgColor={COLOR.bgTabItemWallet}
                borderRadius="20px"
              >
                <Tab
                  width={'33%'}
                  active={tab === 'wallet'}
                  onClick={() => setTab('wallet')}
                >
                  <img
                    src="/empty-wallet-tick.png"
                    style={{
                      width: '30px',
                      height: '30px',
                      marginBottom: '5px',
                    }}
                  />
                  {!isLessThanTablet && <Label ml={5}>Your wallet</Label>}
                </Tab>
                <Tab
                  width={'34%'}
                  active={tab === 'bets'}
                  onClick={() => setTab('bets')}
                >
                  <img
                    src="/coin.png"
                    style={{
                      width: '30px',
                      height: '30px',
                      marginBottom: '5px',
                    }}
                  />
                  {!isLessThanTablet && <Label ml={5}>Your bets</Label>}
                </Tab>
                <Tab
                  width={'33%'}
                  active={tab === 'pool'}
                  onClick={() => setTab('pool')}
                >
                  <img
                    src="/driver.png"
                    style={{
                      width: '30px',
                      height: '30px',
                      marginBottom: '5px',
                    }}
                  />
                  {!isLessThanTablet && <Label ml={5}>Your rooms</Label>}
                </Tab>
              </Box>
            </Box>
            <Box
              className="ant-modal-body-custom"
              fullWidth
              p={isLessThanTablet ? '10px 10px 10px 10px' : '36px 60px'}
              bgColor={COLOR.bgFooter}
            >
              {tab === 'wallet' && (
                <Wallet
                  data={{ balance, contractAddress, tingCoin }}
                  onLogout={() => {
                    onLogout();
                    setModal(false);
                  }}
                  onClose={() => setModal(false)}
                />
              )}
              {tab === 'bets' && <Yourbets onClose={() => setModal(false)} />}
              {tab === 'pool' && <Yourpool onClose={() => setModal(false)} />}
            </Box>
          </>
        )}
        {/* {!isLoggedIn && (
          <Box
            className="ant-modal-body-custom"
            fullWidth
            p={'12px'}
            bgColor={COLOR.bgFooter}
          >
            <LoginWithOTP userEmail={currentUserEmail} userToken={userToken} />
          </Box>
        )} */}
      </Modal>
      {isLoggedIn ? (
        <Button
          height="36px"
          p="6px 16px"
          type="secondary"
          style={{ backgroundColor: '#353546', color: 'white' }}
          fontSizer={18}
          onClick={() => {
            setModal(true);
            setTab('wallet');
          }}
        >
          <img
            src="/wallet_icon.png"
            style={{ width: '27px', height: '27px', marginRight: 12 }}
          />{' '}
          {`${account.slice(0, 6)}......${account.slice(-4)}`}
        </Button>
      ) : (
        <Button
          height="36px"
          p="6px 16px"
          type="secondary"
          fontSizer={18}
          onClick={() => openChooseWallet()}
        >
          {/* <img
            src="/metamask.png"
            style={{ width: '25px', height: '20px', marginRight: 12 }}
          />{' '} */}
          Connect wallet
        </Button>
      )}
    </>
  );
}

UserProfile.propTypes = {};
UserProfile.defaultProps = {};

export default memo(UserProfile);
