import React, { memo, useCallback } from 'react';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import COLOR from 'constants/color';
import Label from 'components/common/Label';
import {
  BET_STATUS,
  POOL_STATUS_OBJECT,
  USER_BET_STATUS,
  POOL_STATUS,
  USER_ACTION,
} from 'constants/status';
import useUserBets from 'hooks/useUserBets';
import { Spin, Popconfirm } from 'antd';
import useDate from 'hooks/useDate';
import { useNavigate } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';

function YourBets(props) {
  const { onClose = () => {} } = props;
  const { formatDate } = useDate();
  const navigate = useNavigate();
  const { loading, userBets = [], onClaim, onCancel } = useUserBets();
  const { isLessThanTablet } = usePlatform();

  const checkPoolClosed = useCallback((poolStatus) => {
    return poolStatus === POOL_STATUS.CLOSED;
  }, []);
  const checkPoolOpening = useCallback((poolStatus) => {
    return poolStatus === POOL_STATUS.READY_TO_USE;
  }, []);

  const onClickDetail = useCallback(
    (id = '') => {
      navigate(`/pool/${id}`);
      onClose();
    },
    [onClose]
  );

  const onGoResult = React.useCallback(
    (poolId) => {
      navigate(`/result/${poolId}`);
      onClose();
    },
    [navigate]
  );
  const renderScore = (matchInfo) => (
    <Label width={'100%'} mt={10}>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.homeTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px 0px' }}
          />
          {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.home
            : '-'}
        </Label>
      </Label>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.awayTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px 0px' }}
          />
          {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.away
            : '-'}
        </Label>
      </Label>
    </Label>
  );

  const CLAIM_WON_FEE_RATE = process.env.REACT_APP_CLAIM_WON_FEE_RATE || 0.02;
  const CLAIM_NOONE_FEE_RATE =
    process.env.REACT_APP_CLAIM_NOONE_WIN_FEE || 0.00001;
  const TOKEN_NAME = process.env.REACT_APP_SMARTCHAIN_TOKEN || 'BNB';

  const getContentClaim = useCallback(
    (betInfo = {}) => {
      if (betInfo?.userWinLoseStatus === USER_BET_STATUS.VICTORY)
        return `Claim now (${Number(CLAIM_WON_FEE_RATE) * 100}% claim fee)`;
      if (betInfo?.userWinLoseStatus === USER_BET_STATUS.NOONEWIN)
        return `Claim now (${Number(CLAIM_NOONE_FEE_RATE)} ${TOKEN_NAME} fee)`;
    },
    [CLAIM_WON_FEE_RATE, CLAIM_NOONE_FEE_RATE, TOKEN_NAME]
  );

  if (loading)
    return (
      <Box flexCenter>
        <Spin />
      </Box>
    );

  return (
    <Box fullWidth flex={{ direction: 'column', rowGap: '18px' }}>
      {userBets.map((item) => (
        <Box
          borderRadius="20px"
          bgColor={COLOR.bgTabWallet}
          p={'15px 15px'}
          fullHeight
          key={item?.poolId}
        >
          <Box flexSpaceBetween mb={'15px'} flex={{ alignItems: 'stretch' }}>
            <Box width={'100%'}>
              <Label
                size={16}
                block
                onClick={() => onClickDetail(item.poolId)}
                pointer
              >
                #{item?.matchInfo?.id} - Room:{' '}
                <Label underline>
                  {String(item.poolId).slice(0, 2)}..
                  {String(item.poolId).slice(-4)}
                </Label>{' '}
                (
                <Label size={16} type="secondary">
                  {POOL_STATUS_OBJECT[item.poolStatus]}
                </Label>
                )
              </Label>
              <Label mt={5} width={'100%'} size={16} block>
                Time:{' '}
                {formatDate(
                  item?.matchInfo?.actualStartTime ||
                    item?.matchInfo?.startTime,
                  'HH:mm, dd MMM'
                )}
              </Label>
              <Box
                onClick={() => onGoResult(item?.matchInfo?.id)}
                pointer
                flexCenter
                width={'100%'}
              >
                {renderScore(item?.matchInfo)}
              </Box>

              <Label width={'100%'} size={16} block>
                {item?.claimedAmount > 0 &&
                  'Claim amount: ' + item?.claimedAmount + ' ' + TOKEN_NAME}
              </Label>

              <Label size={18} mt={4} block type="secondary">
                {item?.betTypeName?.default.replace(
                  'Home ',
                  item?.matchInfo?.homeTeamInfo?.[0]?.fullname?.default + ' ' ||
                    'Home '
                )}
              </Label>
              {item?.optionUserSelect?.name?.default && (
                <Label size={17} mt={6} block>
                  Your choise:{' '}
                  <Label size={18} type="primary">
                    “
                    {item?.optionUserSelect?.name?.default
                      .replace(
                        'Home',
                        item?.matchInfo?.homeTeamInfo?.[0]?.fullname?.default
                      )
                      .replace(
                        'Away',
                        item?.matchInfo?.awayTeamInfo?.[0]?.fullname?.default
                      )}
                    "
                  </Label>
                </Label>
              )}
              {item?.userActionStatus === USER_ACTION.CANCEL && (
                <Label mt={5} size={16} block type="reject">
                  You canceled this bet
                </Label>
              )}
            </Box>
            <Box
              width={'100%'}
              mt={'10px'}
              flex={{
                direction: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              {[
                USER_ACTION.PLACED,
                USER_ACTION.VOTED,
                USER_ACTION.CLAIMED,
              ].includes(item?.userActionStatus) &&
                checkPoolClosed(item.poolStatus) &&
                item?.userWinLoseStatus && (
                  <Button width={'100%'} type={item?.userWinLoseStatus}>
                    {item?.userWinLoseStatusName}
                  </Button>
                )}
            </Box>
          </Box>

          {/* Action */}
          {[
            USER_ACTION.PLACED,
            USER_ACTION.CANCEL,
            USER_ACTION.VOTED,
            USER_ACTION.CLAIMED,
          ].includes(item?.userActionStatus) &&
            checkPoolClosed(item?.poolStatus) && (
              // [USER_BET_STATUS.NOONEWIN, USER_BET_STATUS.VICTORY]?.includes(
              //   item?.userWinLoseStatus
              // ) &&
              <Box>
                {item?.claimedAmount == 0 &&
                  item?.userActionStatus == USER_ACTION.CLAIMED && (
                    <Button
                      type="primary"
                      p="7px 0"
                      fontSize={18}
                      fullWidth
                      disabled
                    >
                      Claimed
                    </Button>
                  )}
                {item?.claimedAmount > 0 &&
                  item?.userActionStatus == USER_ACTION.PLACED &&
                  [USER_BET_STATUS.NOONEWIN, USER_BET_STATUS.VICTORY]?.includes(
                    item?.userWinLoseStatus
                  ) && (
                    <Button
                      type="primary"
                      p="7px 0"
                      fontSize={18}
                      fullWidth
                      onClick={() => onClaim(item?.poolId)}
                    >
                      {getContentClaim(item)}
                    </Button>
                  )}

                {item?.claimedAmount > 0 &&
                  item?.userActionStatus == USER_ACTION.PLACED &&
                  ![
                    USER_BET_STATUS.NOONEWIN,
                    USER_BET_STATUS.VICTORY,
                  ]?.includes(item?.userWinLoseStatus) && (
                    <Button
                      type="primary"
                      p="7px 0"
                      fontSize={18}
                      fullWidth
                      disabled
                    >
                      Undefined claim (Defeat)
                    </Button>
                  )}
                {item?.claimedAmount > 0 &&
                  item?.userActionStatus != USER_ACTION.PLACED && (
                    <Button
                      type="primary"
                      p="7px 0"
                      fontSize={18}
                      fullWidth
                      disabled
                    >
                      Undefined Claim
                    </Button>
                  )}

                {/* <Button
                  type="primary"
                  p="7px 0"
                  fontSize={18}
                  fullWidth
                  disabled={item?.userActionStatus === USER_ACTION.CLAIMED}
                  onClick={() => onClaim(item?.poolId)}
                >
                  {item?.userActionStatus === USER_ACTION.CLAIMED
                    ? 'Claimed'
                    : getContentClaim(item)}
                </Button> */}
              </Box>
            )}
          {item.bet_status === BET_STATUS.CONSOLIDATING && (
            <Box>
              <Button type="default" p="7px 0" fontSize={18} fullWidth>
                Report wrong result
              </Button>
            </Box>
          )}
          {checkPoolOpening(item?.poolStatus) &&
            [USER_ACTION.USER_VOTED, USER_ACTION.PLACED]?.includes(
              item?.userActionStatus
            ) && (
              <Box flexSpaceBetween flex={{ columnGap: '12px' }}>
                {/* <Popconfirm title="Are you sure？" onConfirm={() =>  onCancel(item.poolId)}> */}
                <Button
                  type="default"
                  p="7px 0"
                  onClick={() => onCancel(item.poolId)}
                  fontSize={18}
                  fullWidth
                >
                  Cancel bet
                </Button>
                {/* </Popconfirm> */}
              </Box>
            )}
        </Box>
      ))}
    </Box>
  );
}

YourBets.propTypes = {};
YourBets.defaultProps = {};

export default memo(YourBets);
