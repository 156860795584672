// import fetch from "isomorphic-fetch";


const initialOption = {
  method: "GET",
};

export default async function callApi(
  endpoint = "",
  option = initialOption,
) {
  try {
    const options = {
      method: option.method,
      body: option.body &&
        (option.form_data ? option.body : JSON.stringify(option.body)),
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (option.form_data) {
      delete options.headers['Content-Type'];
    }

    const host = process.env.REACT_APP_ROOT_API
    const urlFetcing = host + endpoint
    const res = await fetch(urlFetcing, options);
    const data = (async () => {
      try {
        return await res.json();
      } catch (e) {
        const _data = await res.text();
        return await Promise.resolve({
          errorCode: res.status,
          data: _data
        });
      }
    })();

    const resData = await data;
    if (res.ok) {
      if (
        (resData.errorCode === 998 ||
          resData.errorCode === 999 ||
          resData.errorCode === 4) &&
        typeof window !== "undefined"
      ) {
        // removeCookie("token");
        if (window.location.pathname === "/profile") {
          window.location.href = "/login";
        }
      }
      return data;
    } else if (res.status === 422) {
      return data;
    } else if (res.status === 400) {
      return data;
    } else if (res.status === 404) {
      return data;
    }

    if (resData && !resData.code) {
      resData.code = resData.status || 999;
      if (!resData.message) resData.message = "Unknown Error";
      delete resData.status;
    }

    throw resData;
  } catch (err) {
    if (!err.data) {
      // console.log(err.message);
    } else {
      const errorCode = err.code;
      if (errorCode === -1 && window.location.href !== "/login") {
        window.location.href = "/login?redirect=true";
        localStorage.clear();
      }
    }
    throw err;
  }
}