import React, { memo } from 'react';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import InputInfo from 'components/common/InputInfo';
import { formatNumberDecimal } from 'utils/format';
import usePlatform from 'hooks/usePlatform';
import { useDispatch, useSelector } from 'react-redux';

function Wallet(props) {
  const { isLessThanTablet } = usePlatform();
  const { data = {}, onLogout = () => {} } = props;
  const { balance = '', contractAddress = '', tingCoin } = data;
  const { account } = useSelector((state) => ({
    account: state.account,
  }));
  const tokenName = process.env.REACT_APP_SMARTCHAIN_TOKEN || '';

  return (
    <Box fullWidth flex={{ direction: 'column' }}>
      <Label size={isLessThanTablet ? 14 : 20} mb={12}>
        Your Address
      </Label>
      <InputInfo text={account} />
      <Box flexSpaceBetween mb={'20px'} mt={'20px'}>
        <Label size={isLessThanTablet ? 14 : 20} >{tokenName} Balance</Label>
        <Label size={isLessThanTablet ? 14 : 20}  type="primary" color={'white'}>
          {balance || 0}
        </Label>
      </Box>
      <Box flexSpaceBetween mb="45px">
        <Label size={isLessThanTablet ? 14 : 20} >TBET Balance</Label>
        <Label size={isLessThanTablet ? 14 : 20}  type="primary">{formatNumberDecimal(tingCoin)}</Label>
      </Box>
      <Button type="outline" height="45px" onClick={onLogout}>
        Disconnect wallet
      </Button>
    </Box>
  );
}

Wallet.propTypes = {};
Wallet.defaultProps = {};

export default memo(Wallet);
