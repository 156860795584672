import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';

function useTab() {
  const dispatch = useDispatch();
  const {
    tab
  } = useSelector((state) => ({
    tab: state.matchTab || 'completed',
  }));

  const onChangeTab = useCallback((type = '')=> {
    dispatch(action.changeTab({ type, }));
  }, [dispatch]);

  return {
    tab,
    onChangeTab
  }
}

export default useTab;