import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';
import useDate from 'hooks/useDate';

function usePoolDetail(props) {
  const { 
    formatDate,
   } = useDate();
  const { poolId = '' } = props;
  const dispatch = useDispatch();
  const {
    loading,
    poolDetail,
    matchInfo,
    matches,
    account,
    betSelected,
  } = useSelector((state) => ({
    loading: state.poolDetailLoading || false,
    poolDetail: state.poolDetail || {},
    matchInfo: state.matchSelected || {},
    matches: state.matches || [],
    account: state.account,
    betSelected: state.betSelected || false,
  }));

  useEffect(() => {
    if (poolId && matches?.length) {
      dispatch(action.getPoolDetail({
        poolId,
        address: account
      }));
    }
  }, [poolId, matches?.length, account]);

  return {
    loading,
    poolDetail,
    matchInfo,
  }
}

export default usePoolDetail;