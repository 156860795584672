import groupBy from 'lodash-es/groupBy';

const groupMatchByDate = (matches = [], asc = true, formatFn = () => {}) => {
  const matchesSort = matches
    .sort(function (a, b) {
      return asc
        ? new Date(a.startTime) - new Date(b.startTime)
        : new Date(b.startTime) - new Date(a.startTime);
    })
    ?.map((item) => ({
      ...item,
      dateFormat: formatFn(item.startTime, 'dd-MMM'),
    }));

  return (
    Object.entries(groupBy(matchesSort, 'dateFormat'))?.map(
      ([date, games]) => ({ date, games })
    ) || []
  );
};

function getTimezoneOffset() {
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? '+' : '-';
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + ':' + z(offset % 60);
}

export { groupMatchByDate, getTimezoneOffset };
