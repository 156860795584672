export const GMT_OPTIONS = [
  {
    label: '(-11:00) Midway, Samoa',
    value: 'Pacific/Midway',
    offset: '-11:00',
  },
  {
    label: '(-10:00) Hawaii',
    value: 'US/Hawaii',
    offset: '-10:00',
  },
  {
    label: '(-09:00) Alaska',
    value: 'US/Alaska',
    offset: '-09:00',
  },
  {
    label: '(-08:00) Pacific',
    value: 'US/Pacific',
    offset: '-08:00',
  },
  {
    label: '(-07:00) Arizona',
    value: 'US/Arizona',
    offset: '-07:00',
  },
  {
    label: '(-06:00) Mexico City',
    value: 'America/Mexico_City',
    offset: '-06:00',
  },
  {
    label: '(-05:00) Eastern',
    value: 'US/Eastern',
    offset: '-05:00',
  },
  {
    label: '(-04:30) Caracas',
    value: 'America/Caracas',
    offset: '-04:30',
  },
  {
    label: '(-04:00) Atlantic',
    value: 'Canada/Atlantic',
    offset: '-04:00',
  },
  {
    label: '(-03:30) Newfoundland',
    value: 'Canada/Newfoundland',
    offset: '-03:30',
  },
  {
    label: '(-03:00) Buenos Aires',
    value: 'America/Buenos_Aires',
    offset: '-03:00',
  },
  {
    label: '(-02:00) Stanley',
    value: 'Atlantic/Stanley',
    offset: '-02:00',
  },
  {
    label: '(-01:00) Azores',
    value: 'Atlantic/Azores',
    offset: '-01:00',
  },
  {
    label: '(00:00) London',
    value: 'Europe/London',
    offset: '00:00',
  },
  {
    label: '(00:00)Lisbon',
    value: 'Europe/Lisbon',
    offset: '00:00',
  },
  {
    label: '(00:00)Dublin',
    value: 'Europe/Dublin',
    offset: '00:00',
  },
  {
    label: '(+01:00) Rome',
    value: 'Europe/Rome',
    offset: '+01:00',
  },
  {
    label: '(+01:00) Amsterdam',
    value: 'Europe/Amsterdam',
    offset: '+01:00',
  },
  {
    label: '(+01:00) Berlin',
    value: 'Europe/Berlin',
    offset: '+01:00',
  },
  {
    label: '(+01:00) Brussels',
    value: 'Europe/Brussels',
    offset: '+01:00',
  },
  {
    label: '(+01:00) Madrid',
    value: 'Europe/Madrid',
    offset: '+01:00',
  },
  {
    label: '(+01:00) Paris',
    value: 'Europe/Paris',
    offset: '+01:00',
  },
  {
    label: '(+02:00) Istanbul',
    value: 'Europe/Istanbul',
    offset: '+02:00',
  },
  {
    label: '(+03:00) Moscow',
    value: 'Europe/Moscow',
    offset: '+03:00',
  },
  {
    label: '(+03:30) Tehran',
    value: 'Asia/Tehran',
    offset: '+03:30',
  },
  {
    label: '(+04:00) Muscat',
    value: 'Asia/Muscat',
    offset: '+04:00',
  },
  {
    label: '(+04:30) Kabul',
    value: 'Asia/Kabul',
    offset: '+04:30',
  },
  {
    label: '(+05:00) Karachi',
    value: 'Asia/Karachi',
    offset: '+05:00',
  },
  {
    label: '(+05:30) Kolkata',
    value: 'Asia/Kolkata',
    offset: '+05:30',
  },
  {
    label: '(+05:45) Kathmandu',
    value: 'Asia/Kathmandu',
    offset: '+05:45',
  },
  {
    label: '(+06:00) Dhaka',
    value: 'Asia/Dhaka',
    offset: '+06:00',
  },
  {
    label: '(+07:00) Bangkok',
    value: 'Asia/Bangkok',
    offset: '+07:00',
  },
  {
    label: '(+07:00) Jakarta',
    value: 'Asia/Jakarta',
    offset: '+07:00',
  },
  {
    label: '(+07:00) Ho Chi Minh',
    value: 'Asia/Ho_Chi_Minh',
    offset: '+07:00',
  },
  {
    label: '(+08:00) Hong Kong',
    value: 'Asia/Hong_Kong',
    offset: '+08:00',
  },
  {
    label: '(+08:00) Taipei',
    value: 'Asia/Taipei',
    offset: '+08:00',
  },
  {
    label: '(+08:00) Singapore',
    value: 'Asia/Singapore',
    offset: '+08:00',
  },
  {
    label: '(+08:00) Kuala_Lumpur',
    value: 'Asia/Kuala_Lumpur',
    offset: '+08:00',
  },
  {
    label: '(+09:00) Seoul',
    value: 'Asia/Seoul',
    offset: '+09:00',
  },
  {
    label: '(+09:00) Tokyo',
    value: 'Asia/Tokyo',
    offset: '+09:00',
  },
  {
    label: '(+09:30) Darwin',
    value: 'Australia/Darwin',
    offset: '+09:30',
  },
  {
    label: '(+10:00) Canberra',
    value: 'Australia/Canberra',
    offset: '+10:00',
  },
  {
    label: '(+10:00) Melbourne',
    value: 'Australia/Melbourne',
    offset: '+10:00',
  },
  {
    label: '(+11:00) Vladivostok',
    value: 'Asia/Vladivostok',
    offset: '+11:00',
  },
  {
    label: '(+12:00) Auckland',
    value: 'Pacific/Auckland',
    offset: '+12:00',
  },
];

export const TAB_INPLAY = 'inPlay';
export const TAB_COMPLETED = 'completed';

export const TAB_OPTIONS = [
  {
    label: 'In play',
    value: TAB_INPLAY,
  },
  {
    label: 'Closed',
    value: TAB_COMPLETED,
  },
];

export const WHITE_PAPER_URL = 'https://whitepaper.tingbet.io/';
