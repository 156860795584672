import React, { useCallback } from 'react';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import { LeftOutlined } from '@ant-design/icons';
import { Spin, Empty } from 'antd';
import COLOR from 'constants/color';
import { useNavigate } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import useDate from 'hooks/useDate';
import { USER_ACTION_OBJECT } from 'constants/status';

export default function BetPoolHistory(props) {
  const { loading = false, data = [], poolId = '' } = props;
  const { getTimeFromNow } = useDate();
  const { isLessThanTablet } = usePlatform();
  const navigate = useNavigate();
  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goChainAddrScanner = (address) => {
    window.open(
      `${process.env.REACT_APP_CHAIN_SCAN}/address/${address}`,
      '_blank'
    );
  };
  const goChainTxScanner = (tx) => {
    window.open(`${process.env.REACT_APP_CHAIN_SCAN}/tx/${tx}`, '_blank');
  };

  return (
    <Box
      fullWidth
      p={isLessThanTablet ? '20px' : '35px 56px'}
      bgColor={COLOR.bgContainer}
      borderRadius="16px"
      flex={{ rowGap: '36px', direction: 'column' }}
    >
      {loading && (
        <Box fullWidth height={'400px'} flexCenter>
          <Spin />
        </Box>
      )}
      {!loading && (
        <>
          <Box flexSpaceBetween>
            <Box>
              <LeftOutlined className="icon-white" onClick={onGoBack} />{' '}
              <Label size={16}>Room Id: {poolId}</Label>
            </Box>
            <Box>
              {!isLessThanTablet && <Label size={18}>Betting history</Label>}
            </Box>
          </Box>
          {/* List bet */}
          <div className="list-result-match">
            {data?.map((item, index) => (
              <Box key={index} p="8px 55px">
                <Box flexSpaceBetween>
                  <Label block>
                    <Label size={isLessThanTablet ? 12 : 18}>
                      Address:{' '}
                      <Label size={isLessThanTablet ? 10 : 18}>
                        {item?.sender}
                      </Label>
                    </Label>
                  </Label>
                  {!isLessThanTablet && (
                    <Label size={18}>
                      {USER_ACTION_OBJECT[item?.userActionStatus]}
                    </Label>
                  )}
                </Box>
                <Box flexSpaceBetween>
                  <Label block>
                    <Label size={isLessThanTablet ? 12 : 18}>
                      Tnx Hash:{' '}
                      <Label
                        size={isLessThanTablet ? 10 : 18}
                        type="secondary"
                        onClick={() => goChainTxScanner(item?.transaction_hash)}
                        style={{ cursor: 'pointer' }}
                      >
                        {item?.transaction_hash?.slice(0, 20)}...
                        {item?.transaction_hash?.slice(-20)}{' '}
                      </Label>
                      {!isLessThanTablet && (
                        <Label size={14}>
                          (
                          {getTimeFromNow(new Date(item.block_timestamp), {
                            addSuffix: true,
                          })}
                          )
                        </Label>
                      )}
                    </Label>
                  </Label>
                  <Label
                    width={isLessThanTablet ? '50%' : null}
                    size={isLessThanTablet ? 13 : 18}
                    type="orange"
                  >
                    {item?.betOption?.name?.default}
                  </Label>
                  {isLessThanTablet && (
                    <Label
                      width={'40%'}
                      size={isLessThanTablet ? 12 : 18}
                      mr={20}
                      right
                    >
                      {USER_ACTION_OBJECT[item?.userActionStatus]} (
                      {getTimeFromNow(new Date(item.block_timestamp), {
                        addSuffix: true,
                      })}
                      )
                    </Label>
                  )}
                </Box>
              </Box>
            ))}
          </div>
        </>
      )}
      {!loading && !data?.length && (
        <Box fullWidth height={'400px'} flexCenter>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Box>
      )}
    </Box>
  );
}

BetPoolHistory.propTypes = {};
BetPoolHistory.defaultProps = {};
