import * as actions from '../actions';
import { CONNECT_WALLET_STATUS } from 'constants/wallet';

const initialState = {
  web3: null,
  contractAddress: '',
  account: null,
  balance: '0',
  tingCoin: null,
  decimal: 0,
  decimal000: '',

  userEmail: '',
  loading: false,
  notification: {
    status: '',
    title: '',
    message: '',
  },
  statusBuyingTingSeed: {
    title: '',
    status: '',
    desc: '',
    transactionHash: '',
  },
  matchSelected: {},
  matches: [],
  matchesFilter: {
    inPlay: [],
    completed: [],
  },
  matchesLoading: false,
  matchTab: 'completed',
  pools: [],
  poolsLoading: false,
  poolDetail: {},
  isPlayerSelected: false,
  poolDetailLoading: false,
  timeZone: 'Asia/Ho_Chi_Minh',
  betOption: [],
  betOptionLoading: false,
  createPoolLoading: false,
  confirmBetModal: {
    show: false,
    matchInfo: {},
    poolInfo: {},
    optionInfo: {},
    onClose: () => {},
    onContinue: () => {},
    type: 'waiting', // confirm / failure / waiting
  },
  systemInfoModal: {
    show: false,
    type: 'wallet', // network / wallet
    onClose: () => {},
    onSwitch: () => {},
  },
  betPoolsHistory: [],
  betPoolsHistoryLoading: false,
  // User
  userPoolsLoading: false,
  userPools: [],
  userBetsLoading: false,
  userBets: [],
  showWalletModal: false,
  connectWallet: {
    status: CONNECT_WALLET_STATUS.NONE,
    type: '',
  },
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.WEB3_CONNECT:
      return {
        ...state,
        web3: action.web3,
        account: action.account,
      };
    case actions.INIT_CONTRACT:
      return {
        ...state,
        contractAddress: action.contractAddress,
        contractInstance: action.contractInstance,
      };
    case actions.GET_TINGBET_GLOBAL_VALUES:
      return {
        ...state,
        tingbetGlobalValues: {
          expiredPoolTs: action.payload.expiredPoolTs,
          minPoolSize: action.payload.minPoolSize,
          maxPoolSize: action.payload.maxPoolSize,
          claimNoOneWinFee: action.payload.claimNoOneWinFee,
          cancelStakingFee: action.payload.cancelStakingFee,
          voteFreezePoolFee: action.payload.voteFreezePoolFee,
          minClaimBetResultTs: action.payload.minClaimBetResultTs,
          registerPoolFee: action.payload.registerPoolFee,
          addMemberBaseFee: action.payload.addMemberBaseFee,
        },
      };

    case actions.STATUS_SUCCESS:
      return {
        ...state,
        notification: {
          status: 'success',
          title: action.title,
          message: action.message,
        },
      };
    case actions.STATUS_ERROR:
      return {
        ...state,
        notification: {
          status: 'error',
          title: action.title,
          message: action.message,
        },
      };
    case actions.RESET_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: '',
          title: '',
          message: '',
        },
      };
    case actions.LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actions.LOADING_KEY:
      return {
        ...state,
        [action.key]: action.value,
      };
    case actions.EMAIL_USER:
      return {
        ...state,
        userEmail: action.userEmail,
      };
    case actions.REGISTER_POOL:
      return {
        ...state,
        registerPoolStatus: {
          status: action.payload.status,
          transactionHash: action.payload.transactionHash,
        },
      };

    case actions.MAKE_BET_OPTION:
      return {
        ...state,
        makeBetOptionStatus: {
          status: action.payload.status,
          transactionHash: action.payload.transactionHash,
        },
      };

    case actions.UPDATE_BET_OPTION:
      return {
        ...state,
        updateBetOptionStatus: {
          status: action.payload.status,
          transactionHash: action.payload.transactionHash,
        },
      };

    case actions.CANCEL_BETTING:
      return {
        ...state,
        cancelBettingStatus: {
          status: action.payload.status,
          transactionHash: action.payload.transactionHash,
        },
      };
    case actions.GET_USER_ACTION:
      return {
        ...state,
        cancelBettingStatus: {
          status: action.payload.status,
          transactionHash: action.payload.transactionHash,
        },
      };
    case actions.CLAIM_TOKENS:
      return {
        ...state,
        claimTokenStatus: {
          status: action.payload.status,
          transactionHash: action.payload.transactionHash,
        },
      };
    case actions.RESET_STATUS_BUY_TING_TOKEN:
      return {
        ...state,
        statusBuyingTingSeed: {
          title: '',
          status: '',
          desc: '',
          transactionHash: '',
        },
      };

    case actions.GET_MATCHES:
      return {
        ...state,
        matches: action.payload || [],
        matchesLoading: false,
        matchesFilter: action.matchesFilter || { inPlay: [], completed: [] },
      };

    case actions.GET_POOLS:
      return {
        ...state,
        pools: action.payload || [],
        poolsLoading: false,
      };

    case actions.GET_POOL_DETAIL:
      return {
        ...state,
        poolDetail: action.payload || [],
        poolDetailLoading: false,
        betSelected: action.betSelected || null,
      };

    case actions.GET_BET_OPTION:
      return {
        ...state,
        betOption: action.payload || [],
        betOptionLoading: false,
      };

    case actions.SELECT_MATCH: {
      return {
        ...state,
        matchSelected: action.payload || {},
      };
    }

    case actions.SELECT_TIMEZONE: {
      return {
        ...state,
        timeZone: action.payload || {},
      };
    }
    case actions.CREATE_POOL:
      return {
        ...state,
        createPoolLoading: false,
      };

    case actions.CHANGE_TAB: {
      return {
        ...state,
        matchTab: action.payload || {},
      };
    }

    case actions.TOGGLE_CONFIRM_BET_MODAL: {
      return {
        ...state,
        confirmBetModal: {
          ...state.confirmBetModal,
          ...action.payload,
        },
      };
    }

    case actions.OPEN_WALLET_POPUP: {
      return {
        ...state,
        showWalletModal: action.showWalletModal,
      };
    }

    case actions.CONNECT_WALLET: {
      return {
        ...state,
        connectWallet: action.connectWallet,
      };
    }

    case actions.TOGGLE_SYSTEM_INFO_MODAL: {
      return {
        ...state,
        systemInfoModal: {
          ...state.systemInfoModal,
          ...action.payload,
        },
      };
    }

    case actions.GET_USER_POOLS:
      return {
        ...state,
        userPools: action.payload || [],
        userPoolsLoading: false,
      };

    case actions.GET_USER_BETS:
      return {
        ...state,
        userBets: action.payload || [],
        userBetsLoading: false,
      };

    case actions.GET_BALANCE:
      return {
        ...state,
        balance: Number(action.balance || 0),
      };

    case actions.GET_BET_POOLS_HISTORY:
      return {
        ...state,
        betPoolsHistory: action.payload,
        betPoolsHistoryLoading: false,
      };

    case actions.GET_TING_COIN:
      return {
        ...state,
        tingCoin: action.tingCoin,
      };

    default:
      return state;
  }
};

export default rootReducer;
