const removeCookie = (name) => {
  if ("undefined" !== typeof document) document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  if ("undefined" !== typeof document) document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
const getCookie = (name) => {
  const nameEQ = name + '=';
  const ca = ("undefined" !== typeof document) ? document.cookie.split(';') : "";
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ')
      c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export { removeCookie, setCookie, getCookie }