import { getWeb3, checkInstalledWallet } from '../utils/ethereum';
import Web3 from 'web3';
// contracts/contractInstance.json
import Factory from '../contracts/TingBet.json';
import { notification } from 'antd';
// import BigNumber from 'bignumber.js';
// import { BigNumber } from "@ethersproject/bignumber";
// const BN = require("bn.js");
import { divPowOf10 } from '../helpers/bignum';
import { MATCH_STATUS } from 'constants/status';
import {
  BET_STATUS,
  POOL_STATUS_OBJECT,
  USER_BET_STATUS,
  POOL_STATUS,
  USER_ACTION,
} from 'constants/status';
import { WALLET_TYPE, CONNECT_WALLET_STATUS } from 'constants/wallet';

import toBN from '../helpers/bignum';
import callAPI from 'services/callApi';
import groupBy from 'lodash-es/groupBy';
import { de } from 'date-fns/locale';

export const EMAIL_USER = 'EMAIL_USER';
export const LOADING = 'LOADING';
export const STATUS_SUCCESS = 'SUCCESS';
export const STATUS_ERROR = 'ERROR';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const STATUS_REGISTER_NEW_POOL = 'STATUS_REGISTER_NEW_POOL';
export const RESET_STATUS_BUY_TING_TOKEN = 'RESET_STATUS_BUY_TING_TOKEN';

export const WEB3_CONNECT = 'WEB3_CONNECT';

async function _try(promise, trackingType = '', trackingName = '') {
  return promise
    .then((res) => {
      return [null, res];
    })
    .catch((err) => {
      return [err, null];
    });
}

export const OPEN_WALLET_POPUP = 'OPEN_WALLET_POPUP';
export const openWallet =
  (show = true) =>
  async (dispatch, getState) => {
    dispatch({
      type: CONNECT_WALLET,
      connectWallet: {
        status: CONNECT_WALLET_STATUS.NONE,
        type: '',
      },
    });
    dispatch({
      type: OPEN_WALLET_POPUP,
      showWalletModal: show,
    });
  };

const formatErrorMsg = (msg) => {
  const head = msg.indexOf('"message": "');
  const content2Tail = msg.slice(head + 12);
  const tail = content2Tail.indexOf('",');
  return content2Tail.slice(0, tail);
};

export const disconnectWallet = () => async (dispatch) => {
  localStorage.removeItem('wallet_type');
  const localWalletKeys = Object.keys(localStorage).filter(
    (x) => !['isReadPolicy', 'isSigned', 'wallet_type'].includes(x)
  );
  localWalletKeys.forEach((k) => localStorage.removeItem(k));
  dispatch({
    type: WEB3_CONNECT,
    web3: null,
    account: null,
  });
  // window.location.href = '/';
};

export const CONNECT_WALLET = 'CONNECT_WALLET';
export const CONNECT_WALLET_FAIL = 'CONNECT_WALLET_FAIL';
export const web3Connect = (type) => async (dispatch) => {
  if (!type && !localStorage.getItem('wallet_type')) {
    // try to auto connect via localstorage, but fail
    return;
  }

  if (!type && localStorage.getItem('wallet_type')) {
    // auto connect via localstorage.
    type = localStorage.getItem('wallet_type');
  }

  dispatch({
    type: CONNECT_WALLET,
    connectWallet: {
      status: CONNECT_WALLET_STATUS.CONNECTING,
      type,
    },
  });

  if (
    type != WALLET_TYPE?.BINANCE?.id &&
    type != WALLET_TYPE?.COINBASE?.id &&
    type != WALLET_TYPE?.WALLETCONNECT?.id &&
    !checkInstalledWallet(type)
  ) {
    dispatch({
      type: CONNECT_WALLET,
      connectWallet: {
        status: CONNECT_WALLET_STATUS.REJECTED,
        type,
      },
    });
    return;
  }

  const web3 = await getWeb3(type);

  if (!web3) {
    localStorage.setItem('wallet_type', '');
    dispatch({
      type: CONNECT_WALLET,
      connectWallet: {
        status: CONNECT_WALLET_STATUS.REJECTED,
        type,
      },
    });
    return;
  }

  dispatch({
    type: OPEN_WALLET_POPUP,
    showWalletModal: false,
  });
  dispatch({
    type: CONNECT_WALLET,
    connectWallet: {
      status: CONNECT_WALLET_STATUS.CONNECTED,
      type,
    },
  });
  localStorage.setItem('wallet_type', type.toString());

  const currentChainId = await web3?.eth?.net.getId();
  const chainId = process.env.REACT_APP_SMARTCHAIN_ID;
  if (currentChainId != chainId) {
    // if (isFirstInit) return;
    dispatch({
      type: TOGGLE_SYSTEM_INFO_MODAL,
      payload: {
        show: true,
        type: 'network',
        onSwitch: async () => {
          try {
            await web3.currentProvider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: Web3.utils.toHex(chainId) }],
            });
            dispatch({
              type: TOGGLE_SYSTEM_INFO_MODAL,
              payload: {
                show: false,
              },
            });
          } catch (e) {
            console.log(e);
          }
        },
      },
    });
  }

  const accounts = (await web3?.eth?.getAccounts()) || [];

  if (accounts.length > 0) {
    const account = accounts[0];
    // console.log('verify:', result);
    const contractAddress = process.env.REACT_APP_SMARTCONTRACT_ADDRESS;
    let contractInstance = new web3.eth.Contract(Factory.abi, contractAddress, {
      transactionConfirmationBlocks: 1,
    });

    dispatch({
      type: WEB3_CONNECT,
      web3,
      account,
    });
    dispatch({
      type: INIT_CONTRACT,
      contractAddress,
      contractInstance,
    });
  } else {
    console.log('Account not found');
    await web3.currentProvider.enable();
  }
};

export const INIT_CONTRACT = 'INIT_CONTRACT';
export const instantiateContracts = () => async (dispatch, getState) => {
  const contractAddress = process.env.REACT_APP_SMARTCONTRACT_ADDRESS;
  const state = getState();
  let web3 = state.web3;
  if (!web3) {
    return;
  }
  let contractInstance = new web3.eth.Contract(Factory.abi, contractAddress, {
    transactionConfirmationBlocks: 1,
  });
  dispatch({
    type: INIT_CONTRACT,
    contractAddress,
    contractInstance,
  });
};

export const GET_BALANCE = 'GET_BALANCE';
export const getBalance = () => async (dispatch, getState) => {
  const state = getState();
  let web3 = state.web3;
  let account = state.account || '';

  let balance = await web3.eth.getBalance(account);
  dispatch({
    type: GET_BALANCE,
    balance: (divPowOf10(Number(balance), 15) / 1000)?.toString(),
  });
};

window.addEventListener('unhandledrejection', function (reason, promise) {
  const errMsg = reason.reason.toString();
  if (errMsg.indexOf('Internal JSON-RPC error') >= 0) {
    notification.error({
      message:
        'Error-Metamask:' +
        errMsg.slice(errMsg.indexOf('message":') + 'message":'.length, -1),
    });
    // console.log('79:',errMsg.slice(errMsg.indexOf('"message":') + 12,-1) );
  }
});

export const GET_TINGBET_GLOBAL_VALUES = 'GET_TINGBET_GLOBAL_VALUES';

export const REGISTER_POOL = 'REGISTER_POOL';
export const registerNewPool =
  (poolId, createPoolFee, whiteList = [], callback) =>
  async (dispatch, getState) => {
    const state = getState();
    const contractInstance = state.contractInstance;
    const account = state.account;
    dispatch({
      type: TOGGLE_CONFIRM_BET_MODAL,
      payload: {
        show: true,
        type: 'waiting',
        title: 'Create your room',
      },
    });
    try {
      if (!localStorage.getItem('isSigned')) {
        const web3 = state.web3;
        await web3.eth.personal.sign(
          'Verify your account after making your first bet. This action are nonfee.',
          account
        );
        localStorage.setItem('isSigned', true);
      }

      const [err, gasAmount] = await _try(
        contractInstance.methods
          .registerNewPool(poolId, whiteList)
          .estimateGas({
            from: account,
            value: toBN(createPoolFee, 18).toString(),
          })
      );

      if (err) {
        notification.error({
          message: `Tingbet error: ${formatErrorMsg(
            err?.message || 'Can not execute this action'
          )}`,
          duration: 10,
        });
        dispatch({
          type: LOADING,
          loading: false,
        });
        dispatch({
          type: TOGGLE_CONFIRM_BET_MODAL,
          payload: {
            show: false,
          },
        });
        return;
      }
      contractInstance.methods
        .registerNewPool(poolId, whiteList)
        .send({
          from: account,
          value: toBN(createPoolFee, 18).toString(),
          gas: gasAmount,
        })
        .then((res) => {
          console.log('158:', res);
          dispatch({
            type: REGISTER_POOL,
            payload: {
              status: 'SUCCESS',
              transactionHash: res.transactionHash,
            },
          });
          dispatch({
            type: TOGGLE_CONFIRM_BET_MODAL,
            payload: { show: false },
          });
          notification.success({
            message: `Registered new room: ${poolId}`,
            placement: 'bottomLeft',
          });
          callback && callback();
        })
        .catch((e) => {
          console.log(e);
          dispatch({
            type: LOADING,
            loading: false,
          });
          dispatch({
            type: TOGGLE_CONFIRM_BET_MODAL,
            payload: { show: false },
          });
        });
    } catch (ex) {
      console.log(ex);
      notification.error({
        message: `Tingbet error: ${formatErrorMsg(ex.message)}`,
        duration: 10,
      });
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({ type: TOGGLE_CONFIRM_BET_MODAL, payload: { show: false } });
    }
  };

export const MAKE_BET_OPTION = 'MAKE_BET_OPTION';
export const makeBetOption =
  (poolId, betOptionId, betFeeAmount, callback) =>
  async (dispatch, getState) => {
    const state = getState();
    const contractInstance = state.contractInstance;
    const account = state.account;
    dispatch({
      type: TOGGLE_CONFIRM_BET_MODAL,
      payload: {
        show: true,
        type: 'waiting',
        title: 'Confirming your bet',
      },
    });
    try {
      if (!localStorage.getItem('isSigned')) {
        const web3 = state.web3;
        await web3.eth.personal.sign(
          'Verify your account after making your first bet. This action are nonfee.',
          account
        );
        localStorage.setItem('isSigned', true);
      }

      const betAmount = toBN(betFeeAmount, 18);
      const [err, gasAmount] = await _try(
        contractInstance.methods
          .makeBetOption(poolId, betOptionId)
          .estimateGas({ from: account, value: betAmount.toString() })
      );

      if (err) {
        notification.error({
          message: `Tingbet error: ${formatErrorMsg(
            err?.message || 'Can not execute this action'
          )}`,
          duration: 10,
        });
        dispatch({
          type: LOADING,
          loading: false,
        });
        dispatch({
          type: TOGGLE_CONFIRM_BET_MODAL,
          payload: {
            show: false,
          },
        });
        return;
      }
      contractInstance.methods
        .makeBetOption(poolId, betOptionId)
        .send({ from: account, value: betAmount.toString(), gas: gasAmount })
        .then((res) => {
          dispatch({
            type: MAKE_BET_OPTION,
            payload: {
              status: 'SUCCESS',
              transactionHash: res.transactionHash,
            },
          });
          notification.success({
            message: `Placed bet in room: ${poolId}`,
            placement: 'bottomLeft',
          });
          dispatch({
            type: TOGGLE_CONFIRM_BET_MODAL,
            payload: {
              show: false,
            },
          });
          callback && callback();
        })
        .catch((e) => {
          console.log(e);
          dispatch({
            type: LOADING,
            loading: false,
          });
          dispatch({
            type: TOGGLE_CONFIRM_BET_MODAL,
            payload: {
              show: true,
              type: 'failure',
            },
          });
        });
    } catch (ex) {
      console.log(ex);
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({
        type: TOGGLE_CONFIRM_BET_MODAL,
        payload: {
          show: true,
          type: 'failure',
        },
      });
    }
  };

export const UPDATE_BET_OPTION = 'UPDATE_BET_OPTION';

export const CANCEL_BETTING = 'CANCEL_BETTING';
export const cancelBetOption =
  (poolId, callback) => async (dispatch, getState) => {
    const state = getState();
    const contractInstance = state.contractInstance;
    const account = state.account;
    dispatch({
      type: TOGGLE_CONFIRM_BET_MODAL,
      payload: {
        show: true,
        type: 'waiting',
        title: 'Cancel your bet',
      },
    });
    try {
      const [err, gasAmount] = await _try(
        contractInstance.methods
          .cancelStaking(poolId)
          .estimateGas({ from: account, value: 0 })
      );

      if (err) {
        notification.error({
          message: `Tingbet error: ${formatErrorMsg(
            err?.message || 'Can not execute this action'
          )}`,
          duration: 10,
        });
        dispatch({
          type: LOADING,
          loading: false,
        });
        dispatch({
          type: TOGGLE_CONFIRM_BET_MODAL,
          payload: {
            show: false,
          },
        });
        return;
      }
      contractInstance.methods
        .cancelStaking(poolId)
        .send({ from: account, value: 0, gas: gasAmount })
        .then((res) => {
          dispatch({
            type: CANCEL_BETTING,
            payload: {
              status: 'SUCCESS',
              transactionHash: res.transactionHash,
            },
          });

          notification.success({
            message: `Cancelled bet in room: ${poolId}`,
            placement: 'bottomLeft',
          });
          dispatch({
            type: TOGGLE_CONFIRM_BET_MODAL,
            payload: {
              show: false,
            },
          });
          callback && callback();
        })
        .catch((e) => {
          console.log(e);
          dispatch({
            type: LOADING,
            loading: false,
          });
          dispatch({
            type: TOGGLE_CONFIRM_BET_MODAL,
            payload: {
              show: false,
            },
          });
        });
    } catch (ex) {
      console.log(ex);
      notification.error({
        message: `Tingbet error: ${formatErrorMsg(ex.message)}`,
        duration: 10,
      });
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({
        type: TOGGLE_CONFIRM_BET_MODAL,
        payload: {
          show: false,
        },
      });
    }
  };

export const GET_USER_ACTION = 'GET_USER_ACTION';
export const getUserAction = (poolId) => async (dispatch, getState) => {
  const state = getState();
  const contractInstance = state.contractInstance;
  const account = state.account;

  try {
    contractInstance.methods
      .getUserAction(poolId, account)
      .call()
      .then((res) => {
        dispatch({
          type: GET_USER_ACTION,
          payload: {
            status: 'SUCCESS',
            transactionHash: res.transactionHash,
          },
        });
        const actions = Object.values(res);
        // optionId != 0: already bet
        // optionId == 0: no betting
        // status = 1 => already claimed win/refund
        console.log({
          optionId: actions[0],
          status: actions[1],
        });
      })
      .catch((e) => {
        console.log('123123', e);
        dispatch({
          type: LOADING,
          loading: false,
        });
      });
  } catch (ex) {
    console.log(ex);
    notification.error({
      message: `Tingbet error: ${formatErrorMsg(ex.message)}`,
      duration: 10,
    });
    dispatch({
      type: LOADING,
      loading: false,
    });
  }
};

export const CLAIM_TOKENS = 'CLAIM_TOKENS';
export const claimFromPool = (poolId) => async (dispatch, getState) => {
  const state = getState();
  const contractInstance = state.contractInstance;
  const account = state.account;
  dispatch({
    type: TOGGLE_CONFIRM_BET_MODAL,
    payload: {
      show: true,
      type: 'waiting',
      title: 'Claim',
    },
  });
  try {
    const [err, gasAmount] = await _try(
      contractInstance.methods
        .claimFromPool(poolId)
        .estimateGas({ from: account, value: 0 })
    );
    if (err) {
      notification.error({
        message: `Tingbet error: ${formatErrorMsg(
          err?.message || 'Can not execute this action'
        )}`,
        duration: 10,
      });
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({
        type: TOGGLE_CONFIRM_BET_MODAL,
        payload: {
          show: false,
        },
      });
      return;
    }

    contractInstance.methods
      .claimFromPool(poolId)
      .send({ from: account, value: 0, gas: gasAmount })
      .then((res) => {
        dispatch({
          type: CANCEL_BETTING,
          payload: {
            status: 'SUCCESS',
            transactionHash: res.transactionHash,
          },
        });
        notification.success({
          message: `Claimed your amount from room: ${poolId}`,
          placement: 'bottomLeft',
        });
        dispatch({
          type: TOGGLE_CONFIRM_BET_MODAL,
          payload: {
            show: false,
          },
        });
      })
      .catch((ex) => {
        console.log('480:', ex);
        notification.error({
          message: `Tingbet error: ${formatErrorMsg(ex.message)}`,
          duration: 10,
        });
        dispatch({
          type: LOADING,
          loading: false,
        });
        dispatch({
          type: TOGGLE_CONFIRM_BET_MODAL,
          payload: {
            show: false,
          },
        });
      });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: LOADING,
      loading: false,
    });
    dispatch({
      type: TOGGLE_CONFIRM_BET_MODAL,
      payload: {
        show: false,
      },
    });
  }
};
export const LOADING_KEY = 'LOADING_KEY';
export const GET_MATCHES = 'GET_MATCHES';
export const getMatches = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: LOADING_KEY,
    key: 'matchesLoading',
    value: true,
  });
  try {
    const result = await callAPI('/general/matches', { method: 'GET' });
    const matchesFilter = {
      inPlay:
        result?.data?.filter((item) => item.status !== MATCH_STATUS.CLOSED) ||
        [],
      completed:
        result?.data?.filter((item) => item.status === MATCH_STATUS.CLOSED) ||
        [],
    };
    dispatch({
      type: GET_MATCHES,
      payload: result?.data || [],
      matchesFilter,
    });
  } catch (ex) {
    dispatch({
      type: LOADING_KEY,
      key: 'matchesLoading',
      value: false,
    });
  }
};

export const GET_POOLS = 'GET_POOLS';
export const getPools =
  ({ matchId, page, limit }) =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: LOADING_KEY,
      key: 'poolsLoading',
      value: true,
    });
    try {
      const result = await callAPI(
        `/pools?matchId=${matchId}&page=${page}&limit=${limit}`,
        { method: 'GET' }
      );
      dispatch({
        type: GET_POOLS,
        payload: result?.data || [],
      });
    } catch (ex) {
      dispatch({
        type: LOADING_KEY,
        key: 'poolsLoading',
        value: false,
      });
    }
  };

export const GET_POOL_DETAIL = 'GET_POOL_DETAIL';
export const getPoolDetail =
  ({ poolId, address = '' }) =>
  async (dispatch, getState) => {
    const state = getState();
    const account = state?.account || '';
    dispatch({
      type: LOADING_KEY,
      key: 'poolDetailLoading',
      value: true,
    });
    try {
      const result = await callAPI(
        `/pool/detail?poolId=${poolId}${account ? `&address=${account}` : ''}`,
        { method: 'GET' }
      );
      if (result?.meta_data?.status === 1) {
        dispatch(selectMatch({ matchId: result?.data?.matchId }));
        const optionUserSelect =
          result?.data?.availableOptions?.find((item) =>
            Boolean(item.is_your_bet)
          ) || {};
        dispatch({
          type: GET_POOL_DETAIL,
          payload: {
            ...result?.data,
            optionUserSelect,
            userWinLoseStatus: result?.data?.finalWinOptionId
              ? result?.data?.isNoOneWin
                ? 'noonewin'
                : Number(result?.data?.finalWinOptionId) ===
                  Number(optionUserSelect?.id)
                ? 'victory'
                : 'defeat'
              : '',
          },
        });
      }
    } catch (ex) {
      dispatch({
        type: LOADING_KEY,
        key: 'poolDetailLoading',
        value: false,
      });
    }
  };

export const GET_BET_OPTION = 'GET_BET_OPTION';
export const getBetOptions = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: LOADING_KEY,
    key: 'betOptionLoading',
    value: true,
  });
  try {
    const result = await callAPI(`/general/bet-options?view=array`, {
      method: 'GET',
    });

    const groupData = groupBy(result?.data, 'categoryId') || [];
    const formatDataSelect = Object.values(groupData)?.map((option = []) => ({
      value: option?.[0]?.categoryId || '',
      label: option?.[0]?.categoryName?.default || '',
      children: option?.map((subOption) => ({
        value: subOption?.id,
        label: subOption?.name?.default,
        indicatorValue: parseFloat(subOption?.indicatorValue),
      })),
    }));
    dispatch({
      type: GET_BET_OPTION,
      payload: formatDataSelect || [],
    });
  } catch (ex) {
    dispatch({
      type: LOADING_KEY,
      key: 'poolsDetailLoading',
      value: false,
    });
  }
};

export const SELECT_MATCH = 'SELECT_MATCH';
export const selectMatch =
  ({ matchId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const matches = state?.matches || [];
    const matchSelected =
      matches?.find((match) => Number(match.id) === Number(matchId)) || {};
    dispatch({
      type: SELECT_MATCH,
      payload: matchSelected || {},
    });
  };

export const SELECT_TIMEZONE = 'SELECT_TIMEZONE';

export const CREATE_POOL = 'CREATE_POOL';
export const createPool = (params, callback) => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: LOADING_KEY,
    key: 'createPoolLoading',
    value: true,
  });
  try {
    const result = await callAPI(`/pool/create`, {
      method: 'POST',
      body: params,
    });

    if (result?.meta_data?.status) {
      const { createPoolFee, poolId } = result?.data || {};
      await dispatch(
        registerNewPool(
          poolId,
          createPoolFee,
          params?.visibilityAddresses,
          callback
        )
      );
      dispatch({
        type: LOADING_KEY,
        key: 'createPoolLoading',
        value: false,
      });
    } else {
      notification.error({
        message: `Tingbet error: ${
          result?.meta_data?.message || 'undefined error'
        }`,
        duration: 10,
      });
      dispatch({
        type: LOADING_KEY,
        key: 'createPoolLoading',
        value: false,
      });
    }
  } catch (ex) {
    dispatch({
      type: LOADING_KEY,
      key: 'createPoolLoading',
      value: false,
    });
  }
};

export const CHANGE_TAB = 'CHANGE_TAB';
export const changeTab =
  ({ type }) =>
  async (dispatch, getState) => {
    dispatch({
      type: CHANGE_TAB,
      payload: type || 'completed',
    });
  };

export const TOGGLE_CONFIRM_BET_MODAL = 'TOGGLE_CONFIRM_BET_MODAL';
export const TOGGLE_SYSTEM_INFO_MODAL = 'TOGGLE_SYSTEM_INFO_MODAL';

export const GET_USER_POOLS = 'GET_USER_POOLS';
export const getUserPools =
  ({ creator }) =>
  async (dispatch, getState) => {
    const state = getState();
    const matches = state.matches || [];
    dispatch({
      type: LOADING_KEY,
      key: 'userPoolsLoading',
      value: true,
    });
    try {
      const result = await callAPI(
        `/pools?creatorAddress=${creator}&page=1&limit=20`,
        { method: 'GET' }
      );
      if (result?.meta_data?.status === 1) {
        dispatch({
          type: GET_USER_POOLS,
          payload:
            result?.data?.map((item) => ({
              ...item,
              matchInfo:
                matches?.find(
                  (match) => Number(match.id) === Number(item.matchId)
                ) || {},
            })) || [],
        });
      }
    } catch (ex) {
      dispatch({
        type: LOADING_KEY,
        key: 'userPoolsLoading',
        value: false,
      });
    }
  };

export const GET_USER_BETS = 'GET_USER_BETS';
export const getUserBets = () => async (dispatch, getState) => {
  const state = getState();
  const matches = state.matches || [];
  const address = state.account || '';

  const contractInstance = state.contractInstance;
  const account = state.account;

  dispatch({
    type: LOADING_KEY,
    key: 'userBetsLoading',
    value: true,
  });
  try {
    const result = await callAPI(`/bet/user_bets?address=${address}`, {
      method: 'GET',
    });
    if (result?.meta_data?.status === 1) {
      const userActionDict = {};
      for (let i = 0; i < result?.data.length; i++) {
        const betItem = result?.data[i];
        const scUserAction = await contractInstance.methods
          .getClaimAllowance(betItem.poolId, account)
          .call();
        // console.log(betItem.poolId, scUserAction);
        if (scUserAction) {
          userActionDict[betItem.poolId] = scUserAction;
        }
      }

      dispatch({
        type: GET_USER_BETS,
        payload:
          result?.data?.map((item) => {
            return {
              ...item,
              claimedAmount: userActionDict[item.poolId] || 0,
              matchInfo:
                matches?.find(
                  (match) => Number(match.id) === Number(item.matchId)
                ) || {},
              optionUserSelect:
                item?.availableOptions?.find((item) => item?.is_your_bet) || {},
            };
          }) || [],
      });
    }
  } catch (ex) {
    dispatch({
      type: LOADING_KEY,
      key: 'userBetsLoading',
      value: false,
    });
  }
};

export const GET_BET_POOLS_HISTORY = 'GET_BET_POOLS_HISTORY';
export const getBetPoolsHistory = (poolId) => async (dispatch, getState) => {
  dispatch({
    type: LOADING_KEY,
    key: 'betPoolsHistoryLoading',
    value: true,
  });
  try {
    const result = await callAPI(`/bet/get_bet_pool_history?poolId=${poolId}`, {
      method: 'GET',
    });
    if (result?.meta_data?.status === 1) {
      dispatch({
        type: GET_BET_POOLS_HISTORY,
        payload: result?.data || [],
      });
    }
  } catch (ex) {
    dispatch({
      type: LOADING_KEY,
      key: 'betPoolsHistoryLoading',
      value: false,
    });
  }
};

export const GET_TING_COIN = 'GET_TING_COIN';
export const getTingCoin = () => async (dispatch, getState) => {
  const state = getState();
  const account = state?.account || '';
  try {
    const result = await callAPI(
      `/account/get_account_info?address=${account}`,
      {
        method: 'GET',
      }
    );
    if (result?.meta_data?.status === 1) {
      dispatch({
        type: GET_TING_COIN,
        tingCoin: Number(result?.data?.rewardTBET || 0),
      });
    }
  } catch (ex) {
    console.log(ex);
  }
};
