import React, { memo, useCallback, useMemo } from 'react';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import { RightOutlined } from '@ant-design/icons';
import COLOR from 'constants/color';
import { formatNumber } from 'utils/format';
import { useNavigate } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import { Spin } from 'antd';
import { groupMatchByDate } from 'utils/common';
import useDate from 'hooks/useDate';
import { TAB_INPLAY } from 'constants/index';

function MatchList(props) {
  const { data = [], loading = false, matchTab } = props;
  const navigate = useNavigate();
  const { formatDate } = useDate();
  const onGotoDetail = useCallback(
    (id) => {
      navigate(`/detail/${id}`);
    },
    [navigate]
  );
  const { isLessThanTablet, isGreaterThanMiniTablet } = usePlatform();

  const groupMatch = useMemo(() => {
    if (data?.length) {
      return groupMatchByDate(data, !!(matchTab == TAB_INPLAY), formatDate);
    }
    return [];
  }, [data, formatDate]);

  const renderMatchItemForDesk = (_item) => (
    <Box
      key={_item?.id}
      fullWidth
      p="24px 33px"
      flexSpaceBetween
      borderRadius="3px"
      bgColor={COLOR.bgRow}
      pointer
      onClick={() => onGotoDetail(_item?.id)}
    >
      <Box flex={{ columnGap: '25px' }}>
        <Label>
          {formatDate(_item?.actualStartTime || _item?.startTime, 'HH:mm')}
        </Label>
        <Label>
          <img
            height={25}
            width={35}
            src={_item?.homeTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ marginRight: '10px', marginBottom: '5px' }}
          />
          {_item?.homeTeamInfo?.[0]?.shortname?.default}
          <Label size={20} ml={20} mr={20}>
            {_item?.resultStats?.goals?.home} -{' '}
            {_item?.resultStats?.goals?.away}
          </Label>
          <img
            height={25}
            width={35}
            src={_item?.awayTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ marginRight: '10px', marginBottom: '5px' }}
          />
          {_item?.awayTeamInfo?.[0]?.shortname?.default}
        </Label>
      </Box>
      <Box flex={{ columnGap: '30px', alignItems: 'center' }}>
        <Label size={18} type="secondary">
          {formatNumber(_item?.totalBetPlayers || 0)} players
        </Label>
        <Label size={18}>{formatNumber(_item?.totalPools || 0)} rooms</Label>
        <Label size={18} type="primary">
          {formatNumber(_item?.totalBetAmount || 0)} {_item?.tokenSymbol} BNB
        </Label>
        {isGreaterThanMiniTablet && (
          <RightOutlined className="icon-white" style={{ fontSize: 20 }} />
        )}
      </Box>
    </Box>
  );

  const renderMatchItemForMob = (_item) => (
    <Box
      key={_item?.id}
      fullWidth
      p="10px 15px"
      flexSpaceBetween
      borderRadius="5px"
      bgColor={COLOR.bgRow}
      pointer
      onClick={() => onGotoDetail(_item?.id)}
    >
      <Box width={'100%'} flex={{ columnGap: '0px' }}>
        <Label mb={5} width={'100%'}>
          {formatDate(_item?.actualStartTime || _item?.startTime, 'HH:mm')}
        </Label>
        <Label width={'100%'}>
          <Label width={'100%'} flexCenter>
            <Label size={16} width={'70%'}>
              <img
                src={_item?.homeTeamInfo?.[0]?.photo}
                alt="flag"
                height={25}
                width={35}
                style={{ marginRight: '10px', marginBottom: '5px' }}
              />
              {_item?.homeTeamInfo?.[0]?.shortname?.default}
            </Label>
            <Label size={16} ml={20} mr={20} width={'20%'} right>
              {_item?.resultStats?.goals
                ? _item?.resultStats?.goals?.home
                : '-'}
            </Label>
          </Label>
          <Label width={'100%'} flexCenter mt={8}>
            <Label size={16} width={'70%'}>
              <img
                src={_item?.awayTeamInfo?.[0]?.photo}
                alt="flag"
                height={25}
                width={35}
                style={{ marginRight: '10px', marginBottom: '5px' }}
              />
              {_item?.awayTeamInfo?.[0]?.shortname?.default}
            </Label>
            <Label size={16} ml={20} mr={20} width={'20%'} right>
              {_item?.resultStats?.goals
                ? _item?.resultStats?.goals?.away
                : '-'}
            </Label>
          </Label>
        </Label>
      </Box>
      <Box
        mt={30}
        width={'100%'}
        flex={{ columnGap: '20px', alignItems: 'center' }}
      >
        <Label type="secondary" size={17}>
          {formatNumber(_item?.totalBetPlayers || 0)} players
        </Label>
        <Label size={17}>{formatNumber(_item?.totalPools || 0)} rooms</Label>
        <Label type="primary" size={17}>
          {formatNumber(_item?.totalBetAmount || 0)} {_item?.tokenSymbol} BNB
        </Label>
      </Box>
    </Box>
  );

  return (
    <Box
      fullWidth
      p={isLessThanTablet ? '20px' : '35px 56px'}
      bgColor={COLOR.bgContainer}
      borderRadius="16px"
      flex={{ rowGap: '36px', direction: 'column' }}
      overFlowY={isLessThanTablet}
    >
      {loading && (
        <Box fullWidth height={'700px'} flexCenter>
          <Spin />
        </Box>
      )}
      {!loading &&
        !!data?.length &&
        groupMatch?.map((item, index) => (
          <Box fullWidth key={index}>
            <Label size={20}>{item.date}</Label>
            <Box mt={'15px'}>
              <Box fullWidth flex={{ direction: 'column', rowGap: '12px' }}>
                {item?.games.map((_item) =>
                  isLessThanTablet
                    ? renderMatchItemForMob(_item)
                    : renderMatchItemForDesk(_item)
                )}
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
}

MatchList.propTypes = {};
MatchList.defaultProps = {};

export default memo(MatchList);
