import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M30 8v.025M30 19.25v.025M30 29.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M30 39.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M30 48v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M30 55.5v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M8.75 19.25v.025M51.25 19.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M51.25 29.25v.025M8.75 29.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M51.25 36.75v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M51.25 44.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M8.75 36.75v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M8.75 44.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3"
        d="M19.5 24.25v.025M19.375 13.75v.025M40.5 24.25v.025M40.625 13.75v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M19.5 34.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M19.5 41.75v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M19.5 49.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M40.5 34.25v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M40.5 41.75v.025"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M40.5 49.25v.025"
      ></path>
    </svg>
  );
}

export default Icon;
