import React, { useCallback, useState } from 'react';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import Button from 'components/common/Button';
import { LeftOutlined } from '@ant-design/icons';
import { Spin, Empty } from 'antd';
import COLOR from 'constants/color';
import { CustomerSupportPopup } from 'components/Popup';
import { useNavigate, Link } from 'react-router-dom';
import { MATCH_STATUS } from 'constants/status';
import usePlatform from 'hooks/usePlatform';
import { RegisterYourPoolPopup, ConfirmYourBetPopup } from 'components/Popup';
import useDate from 'hooks/useDate';
import useUser from 'hooks/useUser';
import { formatNumberDecimal } from 'utils/format';

export default function MatchDetail(props) {
  const { pools = [], loading = false, matchInfo = {} } = props;
  const { formatDate } = useDate();
  const { isLoggedIn, onLogin } = useUser();
  const [helpPopup, setHelpPopup] = useState(false);
  const [registerPopup, setRegisterPopup] = useState(false);
  const { isLessThanTablet } = usePlatform();
  const navigate = useNavigate();
  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const onGoToMatchBet = useCallback(
    (poolId) => {
      navigate(`/pool/${poolId}`);
    },
    [navigate]
  );
  const genId = (id) => (id ? `${String(id).slice(-4)}` : '');

  const renderScoreForDesk = (matchInfo) => (
    <Label>
      <Label type="orange">
        <img
          height={25}
          width={35}
          src={matchInfo?.homeTeamInfo?.[0]?.photo}
          alt="flag"
          style={{ margin: '-1px 10px 2px' }}
        />
        {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
      </Label>{' '}
      <Label size={20} ml={20} mr={20} mb={2}>
        {matchInfo?.resultStats?.goals?.home} -{' '}
        {matchInfo?.resultStats?.goals?.away}
      </Label>
      <Label type="orange">
        <img
          height={25}
          width={35}
          src={matchInfo?.awayTeamInfo?.[0]?.photo}
          alt="flag"
          style={{ margin: '-1px 10px 2px' }}
        />
        {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
      </Label>{' '}
    </Label>
  );
  const renderScoreForMob = (matchInfo) => (
    <Label width={'100%'} mt={10}>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.homeTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px' }}
          />
          {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.home
            : '-'}
        </Label>
      </Label>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.awayTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px' }}
          />
          {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.away
            : '-'}
        </Label>
      </Label>
    </Label>
  );

  return (
    <Box
      fullWidth
      p={isLessThanTablet ? '20px' : '35px 56px'}
      bgColor={COLOR.bgContainer}
      borderRadius="16px"
      flex={{ rowGap: '18px', direction: 'column' }}
    >
      {loading && (
        <Box fullWidth height={'400px'} flexCenter>
          <Spin />
        </Box>
      )}
      {!loading && (
        <>
          <ConfirmYourBetPopup />
          {registerPopup && (
            <RegisterYourPoolPopup
              show={registerPopup}
              onClose={() => setRegisterPopup(false)}
            />
          )}
          <CustomerSupportPopup
            show={helpPopup}
            onClose={() => setHelpPopup(false)}
          />
          {/* Header */}
          <Box flexSpaceBetween position={{ type: 'relative' }}>
            <Box flex={{ alignItems: 'center', columnGap: '15px' }}>
              <LeftOutlined className="icon-white" onClick={onGoBack} />
              <Label>#{matchInfo?.id} </Label>
              <Label size={16}>
                {formatDate(
                  matchInfo?.actualStartTime || matchInfo?.startTime,
                  'HH:mm, dd MMM'
                )}
              </Label>
            </Box>
            {isLessThanTablet && (
              <Label type="secondary">{matchInfo?.statusName?.default}</Label>
            )}
            <Box flexCenter width={isLessThanTablet ? '100%' : null}>
              {isLessThanTablet
                ? renderScoreForMob(matchInfo)
                : renderScoreForDesk(matchInfo)}
            </Box>
            {!isLessThanTablet && (
              <Label type="secondary">{matchInfo?.statusName?.default}</Label>
            )}
          </Box>

          <Box flex={{ direction: 'column', rowGap: '16px' }}>
            <Box flexSpaceBetween>
              <Label>{`Available rooms (${pools.length})`}</Label>
              {matchInfo?.status === MATCH_STATUS.OPENING && (
                <Button
                  p={'2px 12px'}
                  type="outline"
                  fontSize={14}
                  color={COLOR.white}
                  onClick={() =>
                    isLoggedIn
                      ? setRegisterPopup(true)
                      : onLogin(() => setRegisterPopup(true))
                  }
                >
                  + Create your room
                </Button>
              )}
              {matchInfo?.status === MATCH_STATUS.CLOSED && (
                <Link to={`/result/${matchInfo.id}`}>
                  <Label size={18} underline>
                    Final result
                  </Label>
                </Link>
              )}
            </Box>
            {!!!pools?.length && (
              <Box fullWidth flexCenter>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={false}
                />
              </Box>
            )}
            {pools.map((pool = {}, index) => (
              <Box
                bgColor={COLOR.bgRow}
                p="10px 28px"
                flex={{ alignItems: 'center' }}
                borderRadius="16px"
                key={pool?.id}
              >
                {/* Column 1 */}
                <Box width={isLessThanTablet ? '100%' : '35%'}>
                  <Box>
                    <Label>Room {genId(pool?.id)} - </Label>
                    <Label type="primary">
                      {Number(pool.poolSize)} {pool.tokenName}
                    </Label>
                  </Box>
                  <Label type="secondary">
                    {(pool?.betTypeName?.default || '').replace(
                      'Home ',
                      matchInfo?.homeTeamInfo?.[0]?.fullname?.default + ' '
                    )}
                  </Label>
                </Box>
                {/* Column 2 */}
                <Box
                  width={isLessThanTablet ? '100%' : '25%'}
                  style={{ wordBreak: 'break-all' }}
                >
                  {pool?.availableOptions?.length === 2 && (
                    <Box flexCenter>
                      <Box width={isLessThanTablet ? '50%' : '100%'}>
                        <Label mr={8}>
                          {(pool?.availableOptions?.[0]?.name?.default || '')
                            .replace(
                              'Home',
                              matchInfo?.homeTeamInfo?.[0]?.fullname?.default
                            )
                            .replace(
                              'Away',
                              matchInfo?.awayTeamInfo?.[0]?.fullname?.default
                            )}
                        </Label>
                        <Label color={'#5FFF82'} size={13}>
                          {'+' +
                            formatNumberDecimal(
                              pool?.availableOptions?.[0]?.possibleWin
                            )}{' '}
                          {pool?.tokenName}
                        </Label>
                      </Box>
                      <Box width={isLessThanTablet ? '50%' : '100%'}>
                        <Label mr={8}>
                          {(pool?.availableOptions?.[1]?.name?.default || '')
                            .replace(
                              'Home',
                              matchInfo?.homeTeamInfo?.[0]?.fullname?.default
                            )
                            .replace(
                              'Away',
                              matchInfo?.awayTeamInfo?.[0]?.fullname?.default
                            )}
                        </Label>
                        <Label color={'#5FFF82'} size={13}>
                          {'+' +
                            formatNumberDecimal(
                              pool?.availableOptions?.[1]?.possibleWin
                            )}{' '}
                          {pool?.tokenName}
                        </Label>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* Column 3 */}
                <Box width={isLessThanTablet ? '100%' : '25%'}>
                  <Box
                    flex={{ direction: 'column', alignItems: 'center' }}
                    width={isLessThanTablet ? '100%' : '190px'}
                    mt={isLessThanTablet ? '5px' : '0px'}
                    borderRadius="16px"
                    border="1px solid #FFD600"
                    p="2px 16px"
                  >
                    <Label>{pool?.totalBetPlayers} Participants</Label>
                    {pool?.totalBetAmount && (
                      <Label type="primary">
                        {formatNumberDecimal(pool?.totalBetAmount)}{' '}
                        {pool?.tokenName}
                      </Label>
                    )}
                  </Box>
                </Box>
                {/* Button */}
                <Box
                  width={isLessThanTablet ? '100%' : '15%'}
                  mt={'12px'}
                  flex={{
                    direction: 'column',
                    rowGap: '7px',
                    alignItems: 'flex-end',
                  }}
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    width="100%"
                    p="3px 40px"
                    type="primary"
                    fontSize={18}
                    onClick={() => onGoToMatchBet(pool.id)}
                  >
                    {matchInfo?.status === MATCH_STATUS.OPENING && 'Join'}
                    {matchInfo?.status !== MATCH_STATUS.OPENING && 'Detail'}
                  </Button>
                  <Label width={'100%'} size={14}>
                    {pool.betFeeAmount > 0
                      ? `${formatNumberDecimal(pool.betFeeAmount)} ${
                          pool?.tokenName
                        } bet fee`
                      : 'free to join'}
                  </Label>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}

MatchDetail.propTypes = {};
MatchDetail.defaultProps = {};
