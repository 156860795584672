import styled, { css } from 'styled-components';

export const Tab = styled.button`
  cursor: pointer;
  height: 46px;
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  padding: 8px 40px;
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  transition: background-color 200ms linear;
  ${props => props.active && css`
    background-color: rgba(255,255,255,0.1);
    color: #000;
  `}
  ${props => props.width && css`
    width: ${props.width};
  `}
`;
