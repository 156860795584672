import React, { useCallback } from 'react';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import { LeftOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import COLOR from 'constants/color';
import { useNavigate } from 'react-router-dom';
import usePlatform from 'hooks/usePlatform';
import useDate from 'hooks/useDate';

export default function MatchResult(props) {
  const { loading = false, data = {} } = props;
  const { formatDate } = useDate();
  const { isLessThanTablet } = usePlatform();
  const navigate = useNavigate();
  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const renderScoreForDesk = (matchInfo) => (
    <Label>
      <Label type="orange">
        <img
          height={25}
          src={matchInfo?.homeTeamInfo?.[0]?.photo}
          alt="flag"
          style={{ margin: '-1px 10px 2px' }}
        />
        {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
      </Label>{' '}
      <Label size={20} ml={20} mr={20} mb={2}>
        {matchInfo?.resultStats?.goals?.home} -{' '}
        {matchInfo?.resultStats?.goals?.away}
      </Label>
      <Label type="orange">
        <img
          height={25}
          src={matchInfo?.awayTeamInfo?.[0]?.photo}
          alt="flag"
          style={{ margin: '-1px 10px 2px' }}
        />
        {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
      </Label>{' '}
    </Label>
  );
  const renderScoreForMob = (matchInfo) => (
    <Label width={'100%'} mt={10}>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            src={matchInfo?.homeTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px' }}
          />
          {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.home
            : '-'}
        </Label>
      </Label>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            src={matchInfo?.awayTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px' }}
          />
          {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.away
            : '-'}
        </Label>
      </Label>
    </Label>
  );

  return (
    <Box
      fullWidth
      p={isLessThanTablet ? '20px' : '35px 56px'}
      bgColor={COLOR.bgContainer}
      borderRadius="16px"
      flex={{ rowGap: '5px', direction: 'column' }}
    >
      {loading && (
        <Box fullWidth flexCenter>
          <Spin />
        </Box>
      )}
      {!loading && (
        <>
          <Box
            flexSpaceBetween
            flex={{ columnGap: '0px' }}
            position={{ type: 'relative' }}
          >
            <Box
              width={isLessThanTablet ? '40%' : '15%'}
              flex={{ alignItems: 'center', columnGap: '10px' }}
            >
              <LeftOutlined className="icon-white" onClick={onGoBack} />
              <Label size={18}>#{data?.id}</Label>
            </Box>

            {isLessThanTablet && (
              <Box width={'50%'} textAlign={'right'}>
                <Label type="secondary" right>
                  {data?.statusName?.default}
                </Label>
              </Box>
            )}
            <Box flexCenter width={isLessThanTablet ? '100%' : '60%'}>
              {isLessThanTablet
                ? renderScoreForMob(data)
                : renderScoreForDesk(data)}
            </Box>
            {!isLessThanTablet && (
              <Box width={'15%'} textAlign={'right'}>
                <Label type="secondary" right>
                  {data?.statusName?.default}
                </Label>
              </Box>
            )}
          </Box>
          {/* List result stats */}
          <div className="list-result-match">
            <Box flexCenter p="13px 0 16px 0">
              <Box width={'250px'}>
                <Label block center>
                  Corner Kicks
                </Label>
                <Box flexSpaceBetween>
                  <Label>{data?.resultStats?.cornerKicks?.home}</Label>
                  <Label>{data?.resultStats?.cornerKicks?.away}</Label>
                </Box>
              </Box>
            </Box>

            <Box flexCenter p="13px 0 16px 0">
              <Box width={'250px'}>
                <Label block center>
                  Yellow cards
                </Label>
                <Box flexSpaceBetween>
                  <Label>{data?.resultStats?.yellowCards?.home}</Label>
                  <Label>{data?.resultStats?.yellowCards?.away}</Label>
                </Box>
              </Box>
            </Box>
          </div>
        </>
      )}
    </Box>
  );
}

MatchResult.propTypes = {};
MatchResult.defaultProps = {};
