import React, { memo } from 'react';
import styled, { css } from 'styled-components';

export const BoxCustom = styled.div`
  ${(props) =>
    props.overFlowX &&
    css`
      overflow-x: scroll;
    `}
  ${(props) =>
    props.overFlowY &&
    css`
      overflow-y: scroll;
    `}
  ${(props) =>
    props.absoluteCenter &&
    css`
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `}
  ${(props) =>
    props.flexCenter &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    `}
  ${(props) =>
    props.flexSpaceBetween &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    `}
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      flex-wrap: wrap;
      ${props.flex.justifyContent &&
      `justify-content: ${props.flex.justifyContent};`}
      ${props.flex.alignItems && `align-items: ${props.flex.alignItems};`}
    ${props.flex.direction && `flex-direction: ${props.flex.direction};`}
    ${props.flex.columnGap && `column-gap: ${props.flex.columnGap};`}
    ${props.flex.rowGap && `row-gap: ${props.flex.rowGap};`}
    ${props.flex.wrap && `flex-wrap: wrap;`}
    `}
  ${(props) =>
    props.grid &&
    css`
      display: grid;
      ${props.grid.columns && `grid-template-columns: ${props.grid.columns};`}
      ${props.grid.rows && `grid-template-rows: ${props.grid.rows};`}
    ${props.grid.columnGap && `column-gap: ${props.grid.columnGap};`}
    ${props.grid.rowGap && `row-gap: ${props.grid.rowGap};`}
    `}
  // Width
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  // Height
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}
  ${(props) =>
    props.transition &&
    css`
      transition: ${props.transition};
    `}
  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
  ${(props) =>
    props.boxShadow &&
    css`
      box-shadow: ${props.boxShadow};
    `}
  ${(props) =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius};
    `}
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${(props) =>
    props.position &&
    css`
      ${props.position.type && `position: ${props.position.type};`}
      ${props.position.top && `top: ${props.position.top};`}
    ${props.position.bottom && `bottom: ${props.position.bottom};`}
    ${props.position.left && `left: ${props.position.left};`}
    ${props.position.right && `right: ${props.position.right};`}
    ${props.position.zIndex && `z-index: ${props.position.zIndex};`}
    `}
  ${(props) =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  // Margin
  ${(props) =>
    props.mt &&
    css`
      margin-top: ${props.mt};
    `}
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${(props) =>
    props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${(props) =>
    props.mr &&
    css`
      margin-right: ${props.mr};
    `}
  ${(props) =>
    props.m &&
    css`
      margin: ${props.m};
    `}
  // Padding
   ${(props) =>
    props.pt &&
    css`
      padding-top: ${props.pt};
    `}
  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${props.pb};
    `}
  ${(props) =>
    props.pl &&
    css`
      padding-left: ${props.pl};
    `}
  ${(props) =>
    props.pr &&
    css`
      padding-right: ${props.pr};
    `}
  ${(props) =>
    props.p &&
    css`
      padding: ${props.p};
    `}
  
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
  
  // Advance
  ${(props) =>
    props.hover &&
    css`
      :hover {
        ${props.hover.css && props.hover.css}
      }
    `}
`;

function Box(props) {
  return <BoxCustom {...props}>{props.children}</BoxCustom>;
}

export default memo(Box);
