import React, { memo } from 'react';
import { Modal } from 'antd';

function ModalCustom (props) {
  return (
    <Modal wrapClassName='ant-modal-custom' {...props}>
      {props.children}
    </Modal>
  );
};

ModalCustom.propTypes = {};
ModalCustom.defaultProps = {};

export default memo(ModalCustom);