import React, { memo, useCallback } from 'react';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import COLOR from 'constants/color';
import Label from 'components/common/Label';
import { POOL_STATUS_OBJECT } from 'constants/status';
import useUserPool from 'hooks/useUserPools';
import useDate from 'hooks/useDate';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import usePlatform from 'hooks/usePlatform';

function YourPool(props) {
  const { isLessThanTablet } = usePlatform();
  const { loading, userPools } = useUserPool();
  const navigate = useNavigate();
  const { formatDate } = useDate();
  const { onClose } = props;

  const onClickDetail = useCallback(
    (id = '') => {
      navigate(`/pool/${id}`);
      onClose();
    },
    [onClose, navigate]
  );

  const onGoResult = React.useCallback(
    (poolId) => {
      navigate(`/result/${poolId}`);
      onClose();
    },
    [navigate]
  );

  const renderScore = (matchInfo) => (
    <Label width={'100%'} mt={10}>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.homeTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px 0px' }}
          />
          {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.home
            : '-'}
        </Label>
      </Label>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.awayTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px 0px' }}
          />
          {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.away
            : '-'}
        </Label>
      </Label>
    </Label>
  );

  if (loading)
    return (
      <Box flexCenter>
        <Spin />
      </Box>
    );

  return (
    <Box fullWidth flex={{ direction: 'column', rowGap: '18px' }}>
      {userPools.map((pool = {}) => (
        <Box
          borderRadius="20px"
          bgColor={COLOR.bgTabWallet}
          p={'15px 15px'}
          fullHeight
        >
          <Box flexSpaceBetween mb={'15px'} flex={{ alignItems: 'stretch' }}>
            <Box width={'100%'}>
              <Label size={16} block>
                #{pool?.matchInfo.id} - Room: {String(pool.id).slice(0, 2)}..
                {String(pool.id).slice(-4)} (
                <Label size={16} type="secondary">
                  {POOL_STATUS_OBJECT[pool?.status]}
                </Label>
                )
              </Label>
              <Label size={16} block>
                Time:{' '}
                {formatDate(
                  pool?.matchInfo?.actualStartTime ||
                    pool?.matchInfo?.startTime,
                  'HH:mm, dd MMM'
                )}
                {/* {!isLessThanTablet && ': '}
                {isLessThanTablet && <br />}
                <Label size={16} ml={4} type="orange">
                  {pool?.matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
                </Label>{' '}
                (Home)
                {isLessThanTablet && <br />}
                {!isLessThanTablet && ' - '}
                <Label size={16} ml={4} type="orange">
                  {pool?.matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
                </Label>{' '}
                (Away)
                {isLessThanTablet && <br />} */}
              </Label>
              <Box
                onClick={() => onGoResult(pool?.matchInfo?.id)}
                pointer
                flexCenter
                width={'100%'}
              >
                {renderScore(pool?.matchInfo)}
              </Box>
              <Label size={16} block type="secondary">
                {pool?.betTypeName?.default}
              </Label>
              {/* <Label size={14} block>White list: <Label size={14} type='secondary'>“Home”</Label></Label> */}
            </Box>
            <Box
              width={'100%'}
              flex={{
                direction: 'column',
                alignItems: 'flex-end',
                rowGap: '3px',
              }}
            >
              <Label width={'100%'} size={14} left>
                Staking amount: {Number(pool?.poolSize)} {pool?.betToken}
              </Label>
              <Label width={'100%'} size={14} left>
                Joining fee: {Number(pool?.betFeeAmount)} {pool?.betToken}
              </Label>
            </Box>

            <Box width={'100%'} mt={isLessThanTablet ? '10px' : '5px'}>
              {pool?.status > 1 && (
                <Button
                  width={'100%'}
                  type="outline"
                  border={`2px solid ${COLOR.white}`}
                  color={COLOR.white}
                  fontSize={16}
                  borderRadius={'10px'}
                  p={'2px 36px'}
                  onClick={() => onClickDetail(pool?.id)}
                >
                  Detail
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

YourPool.propTypes = {};
YourPool.defaultProps = {};

export default memo(YourPool);
