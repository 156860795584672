import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="42"
      fill="none"
      viewBox="0 0 38 42"
    >
      <path
        fill="#DB579C"
        stroke="#DB579C"
        d="M3.604 8.62h0l12.353-7.144s0 0 0 0a6.09 6.09 0 016.064-.001l12.372 7.144h.002a6.151 6.151 0 013.043 5.258v14.248a6.061 6.061 0 01-3.021 5.255h0l-12.373 7.144h-.001a6.09 6.09 0 01-6.064.001L3.608 33.381h-.002a6.112 6.112 0 01-3.023-5.256v-14.25a6.061 6.061 0 013.021-5.254zm17.229 23.067l.01-.01.008-.01c.224-.248.4-.525.527-.829.126-.301.206-.637.206-.984a2.57 2.57 0 00-.203-.977 2.649 2.649 0 00-.53-.836l-.009-.01-.01-.01a2.581 2.581 0 00-2.813-.547c-.339.133-.596.316-.832.53l-.01.008-.01.01a2.598 2.598 0 00-.75 1.832c0 .32.049.66.184.984l.007.016.007.015c.14.28.31.55.534.799l.018.02.02.017c.237.214.495.398.836.53a2.562 2.562 0 002.81-.548zM19 10.083c-1.13 0-2.062.932-2.062 2.063v10.937c0 1.13.932 2.063 2.062 2.063s2.063-.932 2.063-2.063V12.146c0-1.13-.932-2.063-2.063-2.063z"
      ></path>
    </svg>
  );
}

export default Icon;
