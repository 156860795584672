// external
import { notification } from 'antd';

const handleSnackbar = ({ status, title, message, dispatch }) => {

  const config = {
    message: title,
    description: message,
    placement: "bottomLeft",
  }

  if (status === "error") {
    return notification.error({
      ...config,
      duration: 0,
      onClose: () => dispatch({
        type: "RESET_NOTIFICATION"
      })
    })
  }
  if (status === "success") {
    return notification.success({ ...config })
  }
  return notification.open({ ...config })
}

export default handleSnackbar