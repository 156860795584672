import React, { memo, useMemo } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import InputInfo from 'components/common/InputInfo';
import { Select, InputNumber, Cascader, Form, Input } from 'antd';
import useCreate from 'hooks/useCreatePool';
import useDate from 'hooks/useDate';
import usePlatform from 'hooks/usePlatform';

const MIN_POOL_SIZE = process.env.REACT_APP_MIN_POOL_SIZE || 0.0001;
const MAX_POOL_SIZE = process.env.REACT_APP_MAX_POOL_SIZE || 100;

function RegisterYourPool(props) {
  const { show = false, onClose = () => {} } = props;
  const [showWhitelist, setShowWhitelist] = React.useState(false);
  const { isLessThanTablet } = usePlatform();
  const {
    betOption = [],
    matchInfo,
    form,
    onFinish,
    loadingCreate,
  } = useCreate({ onClosePopup: onClose });
  const { formatDate } = useDate();
  const tokenName = useMemo(
    () => matchInfo?.stats?.currency ?? '',
    [matchInfo?.stats?.currency]
  );

  return (
    <Modal
      visible={show}
      title={'Register your room'}
      onCancel={onClose}
      footer={null}
      width={isLessThanTablet ? '100%' : '60%'}
      maskClosable={false}
    >
      <Form form={form} onFinish={onFinish}>
        <Box flex={{ direction: 'column', rowGap: '20px' }}>
          <Box flex={{ columnGap: '54px' }}>
            <Label size={16}>
              #{matchInfo?.id}{' '}
              {formatDate(
                matchInfo?.actualStartTime || matchInfo?.startTime,
                'HH:mm, dd MMM'
              )}
            </Label>
            <Label>
              <Label type="orange">
                {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
              </Label>{' '}
              (Home) -{' '}
              <Label type="orange">
                {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
              </Label>{' '}
              (Away)
            </Label>
          </Box>

          <Box flex={{ direction: 'column', rowGap: '18px' }}>
            <Box flex={{ direction: 'column', rowGap: '8px' }}>
              <Label block>Bet type</Label>
              <Form.Item
                name="betTypeId"
                rules={[{ required: true, message: 'Bet type is required' }]}
              >
                <Cascader
                  style={{ width: '100%' }}
                  options={betOption}
                  placeholder="Please select"
                />
              </Form.Item>
            </Box>

            <Box flex={{ direction: 'column', rowGap: '8px' }}>
              <Label block>Room size (staking amount)</Label>
              <Form.Item
                name="poolSize"
                rules={[
                  {
                    validator(rule, value, callback) {
                      if (!value)
                        return Promise.reject(
                          new Error('Staking amount is required')
                        );
                      if (
                        value &&
                        (Number(value) < Number(MIN_POOL_SIZE) ||
                          Number(value) > Number(MAX_POOL_SIZE))
                      )
                        return Promise.reject(
                          new Error(
                            `Staking amount must be between ${MIN_POOL_SIZE} and ${MAX_POOL_SIZE}`
                          )
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  addonAfter={tokenName}
                  defaultValue={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Box>

            <Box flex={{ direction: 'column', rowGap: '8px' }}>
              <Label block>Joining fee (participants pay you)</Label>
              <Form.Item
                name="betFeeAmount"
                rules={[
                  {
                    validator(rule, value, callback) {
                      if (
                        value &&
                        Number(value) >=
                          Number(form.getFieldValue('poolSize') || 0)
                      )
                        return Promise.reject(new Error('Joining fee invalid'));
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  addonAfter={tokenName}
                  defaultValue={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Box>

            <Box flexSpaceBetween>
              <Label size={16}>
                White list
                <Form.Item noStyle shouldUpdate={true}>
                  {({ getFieldValue }) =>
                    getFieldValue('visibilityAddresses') ? (
                      <Label size={16} type="secondary">
                        (
                        {
                          getFieldValue('visibilityAddresses')?.split('\n')
                            ?.length
                        }{' '}
                        addresses)
                      </Label>
                    ) : (
                      ''
                    )
                  }
                </Form.Item>
              </Label>
              <Label
                type="primary"
                size={16}
                pointer
                onClick={() => setShowWhitelist(true)}
              >
                + Add/Edit addresses
              </Label>
            </Box>

            {showWhitelist && (
              <Box fullWidth>
                <Form.Item name={'visibilityAddresses'}>
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder="Paste addresses here, seperating them by break line ..."
                  />
                </Form.Item>
              </Box>
            )}

            {/* Button Action */}
            <Box
              m="0 -24px"
              width="calc(100% + 48px)"
              style={{ borderBottom: '1px solid #5B4D81' }}
            />
            <Button
              disabled={loadingCreate}
              htmlType="submit"
              type="primary"
              p="6px 0"
              fontSize={18}
            >
              Register pool
            </Button>
          </Box>
        </Box>
      </Form>
    </Modal>
  );
}

export default memo(RegisterYourPool);
