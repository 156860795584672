import React, { memo, useEffect } from 'react';
import MatchBet from 'components/MatchBet';
import Box from 'components/common/Box';
import { useParams } from 'react-router-dom';
import usePoolDetail from 'hooks/usePoolDetail';

function MatchBetContainer (props) {
  let params = useParams();
  const { loading, poolDetail, matchInfo } = usePoolDetail({ poolId: params.id });

  useEffect(()=> {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
   }, []);

  return (
    <Box fullWidth p={'10px 0'}>
      <MatchBet loading={loading} data={poolDetail} matchInfo={matchInfo} />
    </Box>
  );
};

MatchBetContainer.propTypes = {};
MatchBetContainer.defaultProps = {};

export default memo(MatchBetContainer);