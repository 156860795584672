import React, { memo } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import InputInfo from 'components/common/InputInfo';
import usePlatform from 'hooks/usePlatform';

function SharePool(props) {
  const { isLessThanTablet } = usePlatform();
  const { show = false, link = '', onClose = () => {} } = props;
  return (
    <Modal
      visible={show}
      title={<Label>Share this room</Label>}
      onCancel={onClose}
      footer={null}
      width={isLessThanTablet ? '95%' : '50%'}
    >
      <Box flex={{ direction: 'column', rowGap: '40px' }}>
        <Box fullWidth flex={{ direction: 'column' }}>
          {/* <Label center mb={12}>Share this to your friends</Label> */}
          <InputInfo text={link} />
        </Box>
      </Box>
    </Modal>
  );
}

export default memo(SharePool);
