// core
import React from "react"

// internal
import { removeCookie } from "../../utils/cookies"
import "./styles.scss"

const Header = ({ userEmail = "", userToken = "" }) => {

  const [showToggle, setShowToggle] = React.useState(false)
  const [isLogin, setIsLogin] = React.useState(false)

  React.useEffect(() => {
    if (userToken && userEmail) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [userToken, userEmail])

  const onClickAvatar = () => setShowToggle(prevState => !prevState)

  const handleLogout = () => {
    removeCookie("tingee-token")
    removeCookie("tingee-user-email")
    window.location.href = "/"
  }

  return (
    <header className="header">
      <div className="header-container">

        {isLogin && (
          <React.Fragment>
            <div className="info-user">
              <div className="email-user" onClick={onClickAvatar}>
                <img
                  src="./user.svg"
                  alt={userEmail}
                  width={40}
                  height={40}
                />
                <p>{userEmail}</p>
              </div>
              <div className={`logout ${showToggle ? "toggle-logout" : ""}`}>
                <button
                  aria-label="Button logout"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            </div>
            {showToggle && (
              <div
                className="pseudo-ele"
                onClick={() => setShowToggle(false)}
              />
            )}
          </React.Fragment>
        )}
      </div>

    </header>
  )
}

export default React.memo(Header)