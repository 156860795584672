import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as action from 'actions';

function useConnectWallet(props) {
  const { onClosePopup } = props || {};
  const dispatch = useDispatch();
  const { showWalletModal, connectWallet } = useSelector((state) => ({
    showWalletModal: state.showWalletModal,
    connectWallet: state.connectWallet,
  }));

  const onCloseModal = useCallback(() => {
    dispatch(action.openWallet(false));
  }, [dispatch]);

  const onConnect = useCallback(
    async (type) => {
      try {
        dispatch(action.web3Connect(type));
      } catch (err) {
        console.log('err', err);
      }
    },
    [dispatch]
  );

  return {
    showWalletModal,
    connectWallet,
    onCloseModal,
    onConnect,
  };
}

export default useConnectWallet;
