import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';

function useUserPools(params) {
  const dispatch = useDispatch();
  const {
    loading,
    userPools,
    account,
  } = useSelector((state) => ({
    loading: state.userPoolsLoading,
    userPools: state.userPools,
    account: state.account,
  }));

  useEffect(() => {
    if (account) {
      dispatch(action.getUserPools({ creator: account }));
    }
  }, [account, dispatch])

  return {
    loading,
    userPools,
  }
}

export default useUserPools;