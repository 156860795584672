import { Grid } from 'antd';

const { useBreakpoint } = Grid;

function usePlatform() {
  const screens = useBreakpoint();
  const tabletSize = !screens?.xs && screens?.sm && !screens?.md;
  const mobileSize = screens?.xs && !screens?.sm;
  const isLessThanTablet = (screens?.xs || screens?.sm) && !screens?.md;
  const isGreaterThanMiniTablet = window.innerWidth >= 1000;
  const isLessThanSmallPhone = window.innerWidth < 400;
  return {
    isTablet: tabletSize,
    isMobile: mobileSize,
    isLessThanTablet,
    isGreaterThanMiniTablet,
    isLessThanSmallPhone
  };
}

export default usePlatform;
