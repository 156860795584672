import React, { memo, useCallback } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';
import COLOR from 'constants/color';
import SwitchIcon from 'components/common/Icon/SwitchIcon';
import WarningIcon from 'components/common/Icon/WarningIcon';

function SystemInfo(props) {
  const dispatch = useDispatch();
  const {
    systemInfoModal,
  } = useSelector((state) => ({
    systemInfoModal: state.systemInfoModal || {},
  }));

  const { 
    show = false,
    type = 'network',
    onClose = () => {},
    onSwitch = () => {},
   } = systemInfoModal || {};

  const handleClose = useCallback(()=> {
    dispatch({
      type: action.TOGGLE_SYSTEM_INFO_MODAL,
      payload: {
        show: false
      }
    });
    onClose && onClose();
  }, [onClose]);

  const onDisconnect = useCallback(()=> {
    window.location.reload(false);
  }, [])

  if (show) {
    if (type === 'network') {
      return (
        <Modal
          visible={show}
          title={'Check your network'}
          onCancel={handleClose}
          footer={null}
          width={'403px'}
        >
          <Box flex={{ direction: 'column', rowGap: '12px', alignItems: 'center' }}>
            <Label size={16}>Currently <Label size={16} type='primary'>TingBet</Label> only supports in <Label size={16} type='secondary'>Binance Smart Chain (BSC)</Label>. Please switch to <Label size={16} type='secondary'>BSC Mainnet</Label> network to continue.</Label>
            <SwitchIcon />
            <Button color={COLOR.primary} type='outline' borderColor={COLOR.primary} fullWidth p={'13px 0'}><WarningIcon /> <span style={{ marginLeft: 8 }}>Please switch your network to continue</span></Button>
            <Button fullWidth type='secondary' p={'13px 0'} fontSize={18} onClick={onSwitch}>Switch to Binance network (BSC)</Button>
            <Button fullWidth type='outline' p={'10px 0'} fontSize={18} onClick={onDisconnect}>Disconnect wallet</Button>
          </Box>
        </Modal>
      )
    }
    if (type === 'wallet') {
      return (
        <Modal
          visible={show}
          title={'Connect to wallet'}
          onCancel={handleClose}
          footer={null}
          width={'403px'}
        >
          <Box flex={{ direction: 'column', rowGap: '12px', alignItems: 'center' }}>
            <Label size={16}>Your <Label type='secondary' size={16}>Metamask</Label> plugin is not detected. Please <a target={'_blank'} href='https://metamask.io/download/' style={{ color: 'white', textDecoration: 'underline' }}>install Metamask plugin</a> to continue.</Label>
            <Button fullWidth type='outline' p={'10px 0'} fontSize={18} onClick={handleClose}>Close</Button>
          </Box>
        </Modal>
      ) 
    }
  }
  return <></>
};

export default memo(SystemInfo);