import styled from 'styled-components';
import COLOR from 'constants/color';

export const Container = styled.div`
  width: 100%;
  padding: 30px 0;
  background-color: ${COLOR.bgFooter};
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1073px;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;

  label.title {
    font-family: 'Arial Rounded MT Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${COLOR.secondary};
  }

  a.item {
    font-family: 'Arial Rounded MT Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${COLOR.white};
  }
`;

export const ContractInfo = styled.div`
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  background: rgba(70, 70, 89, 0.5);
  border-radius: 10px;
  padding: 2px 16px;
  svg {
    margin-left: 6px;
  }
`;

export const ContractInfoMob = styled.div`
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  background: rgba(70, 70, 89, 0.5);
  border-radius: 10px;
  padding: 2px 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  svg {
    margin-left: 6px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 83px;
`;

export const ListIcon = styled.div`
  display: flex;
  column-gap: 20px;

  span[role="img"] svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: #B6ADCF;
  }
`;