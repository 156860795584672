import React, { memo, useEffect } from 'react';
import MatchDetail from 'components/MatchDetail';
import Box from 'components/common/Box';
import { useParams } from 'react-router-dom';
import usePools from 'hooks/usePools';

function MatchDetailContainer (props) {
  let params = useParams();
  const { 
    pools,
    loading,
    matchInfo
   } = usePools({ matchId: params.matchId });

   useEffect(()=> {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
   }, []);

  return (
    <Box fullWidth p={'10px 0'}>
      <MatchDetail pools={pools} loading={loading} matchInfo={matchInfo} />
    </Box>
  );
};

MatchDetailContainer.propTypes = {};
MatchDetailContainer.defaultProps = {};

export default memo(MatchDetailContainer);