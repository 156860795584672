import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';

function usePools(props) {
  const { matchId = '' } = props;
  const dispatch = useDispatch();
  const {
    loading,
    pools,
    matchInfo,
    matches,
  } = useSelector((state) => ({
    loading: state.poolsLoading || false,
    pools: state.pools || [],
    matchInfo: state.matchSelected || {},
    matches: state.matches || [],
  }));

  useEffect(()=> {
    if (matchId && matches?.length) {
      dispatch(action.getPools({
        matchId,
        page: 1,
        limit: 10
      }));
      dispatch(action.selectMatch({ matchId, }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchId, matches?.length, dispatch]);

  return {
    loading,
    pools,
    matchInfo,
  }
}

export default usePools;