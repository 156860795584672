import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';
import { Form } from 'antd';

function useCreatePool(props) {
  const { onClosePopup } = props || {};
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    loading,
    betOption,
    matchInfo,
    account,
    loadingCreate
  } = useSelector((state) => ({
    loading: state.betOptionLoading || false,
    betOption: state.betOption || [],
    matchInfo: state.matchSelected || {},
    loadingCreate: state.createPoolLoading || false,
    account: state.account || '',
  }));

  const onFinish = useCallback((formValue)=> {
    if (formValue) {
      const params = {
        ...formValue,
        betFeeAmount: formValue?.betFeeAmount || 0,
        betTypeId: formValue?.betTypeId?.[1],
        matchId: matchInfo?.id,
        creator: account,
        visibilityAddresses: formValue?.visibilityAddresses?.split('\n')?.map((item) => String(item)?.trim()) || [],
      };
      const callback = () => {
        onClosePopup();
        dispatch(action.getPools({ matchId: matchInfo?.id, page: 1, limit: 10 }));
      }
      dispatch(action.createPool(params, callback));
    }
  }, [matchInfo?.id, account]);

  useEffect(()=> {
    if (!betOption?.length) {
      dispatch(action.getBetOptions());
    }
  }, []);

  return {
    loading,
    betOption,
    matchInfo,
    form,
    onFinish,
    loadingCreate,
  }
}

export default useCreatePool;