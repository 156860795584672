import React, { memo, useState } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import usePlatform from 'hooks/usePlatform';
import useConnectWallet from 'hooks/useConnectWallet';
import { CONNECT_WALLET_STATUS } from 'constants/wallet';

import { useLocalStorage } from 'react-use';
import { getListOfWallets, getProviderName } from 'utils/ethereum';

function ChooseWallet(props) {
  const { isLessThanTablet, isGreaterThanMiniTablet, isLessThanSmallPhone } =
    usePlatform();
  const { showWalletModal, connectWallet, onCloseModal, onConnect } =
    useConnectWallet();
  const [show, setShow] = useState(showWalletModal);
  const [selectedWallet, setSelectedWallet] = useState('');
  const [selectedWalletName, setSelectedWalletName] = useState('');

  console.log('22:connectWallet:', connectWallet);
  const all_wallets = getListOfWallets();
  return (
    <Modal
      visible={showWalletModal}
      title={<Label size={16}>Connect wallet</Label>}
      onCancel={onCloseModal}
      footer={null}
      width={'370px'}
    >
      <Box flex={{ direction: 'column', rowGap: '5px' }}>
        <Label size={14} block>
          Start by connecting with one of the wallets below. Be sure to store
          your private keys or seed phrase securely. Never share them with
          anyone.
        </Label>
        <Box
          flex={{
            alignItems: 'center',
            columnGap:
              !isLessThanSmallPhone && isLessThanTablet ? '48px' : '28px',
          }}
          position={{ type: 'relative' }}
        >
          {all_wallets.map((wallet, index) => (
            <Box
              textAlign={'center'}
              flex={{ direction: 'column', rowGap: '2px' }}
              width={'70px'}
              mt={isLessThanTablet ? '20px' : '20px'}
              pointer
              onClick={() => {
                onConnect(wallet.id);
                // setSelectedWallet(wallet.id);
                // setSelectedWalletName(wallet.name);
              }}
            >
              <img
                width={60}
                height={60}
                style={{ marginLeft: '15px' }}
                src={wallet.icon}
              />
              <Label size={14} mt={2} block ml={wallet.ml}>
                {wallet.name}
              </Label>
            </Box>
          ))}
        </Box>

        {connectWallet.status == CONNECT_WALLET_STATUS.REJECTED && (
          <Label center type="primary" size={16} mt={1} mb={1} block>
            Can not find {connectWallet.type}. Please install it to continue.
          </Label>
        )}
        {/* <Label left size={10} mt={5} mb={20} block>
          Current agent: {window?.navigator?.userAgent || ''}
          <br />
          Current ethereum environment:{' '}
          {JSON.stringify(getProviderName()).replace(',', ', ')}
          <br />
        </Label> */}
      </Box>
    </Modal>
  );
}

export default memo(ChooseWallet);
