import React, { memo } from 'react';
import MatchResult from 'components/MatchResult';
import Box from 'components/common/Box';
import { useParams } from 'react-router-dom';
import useMatchResult from 'hooks/useMatchResult';

function MatchResultContainer (props) {
  let params = useParams();
  const { 
    loading,
    matchInfo
   } = useMatchResult({ matchId: params.matchId });
  return (
    <Box fullWidth p={'10px 0'}>
      <MatchResult loading={loading} data={matchInfo} />
    </Box>
  );
};

MatchResultContainer.propTypes = {};
MatchResultContainer.defaultProps = {};

export default memo(MatchResultContainer);