export const BET_STATUS = {
  OPENED: 'OPENED',
  ENDED: 'ENDED',
  CONSOLIDATING: 'CONSOLIDATING'
};

export const BET_STATUS_OBJECT = {
  [BET_STATUS.OPENED]: 'Opened',
  [BET_STATUS.CONSOLIDATING]: 'Consolidating',
  [BET_STATUS.ENDED]: 'Ended',
};

export const POOL_STATUS = {
  NEW: 0,
  WAITING_APPROVE: 1,
  READY_TO_USE: 2,
  CONSOLIDATING: 3,
  CLOSED: 4,
  CANCELLED: 5,
  EXPIRED: 6
};


export const POOL_STATUS_OBJECT = {
  [POOL_STATUS.NEW]: 'Not yet registration',
  [POOL_STATUS.WAITING_APPROVE]: 'Waiting approval',
  [POOL_STATUS.READY_TO_USE]: 'Open',
  [POOL_STATUS.CONSOLIDATING]: 'Summarizing',
  [POOL_STATUS.CLOSED]: 'Closed',
  [POOL_STATUS.CANCELLED]: 'Canceled',
  [POOL_STATUS.EXPIRED]: 'Expired',
};

export const MATCH_STATUS = {
  OPENING: 'opening',
  CLOSED: 'ended',
  CONSOLIDATING: 'consolidating',
};

export const MATCH_STATUS_OBJECT = {
  [MATCH_STATUS.OPENING]: 'Opening',
  [MATCH_STATUS.CLOSED]: 'Closed bet',
  [MATCH_STATUS.CONSOLIDATING]: 'Summarizing',
};


export const USER_BET_STATUS = {
  VICTORY: 'victory',
  DEFEAT: 'defeat',
  NOONEWIN: 'noonewin',
  CONSOLIDATING: 'consolidating'
};

export const USER_ACTION = {
  CANCEL: 0,
  CLAIMED: 1,
  USER_VOTED: 2,
  PLACED: 10,
  UNDEFINED: -1
}

export const USER_ACTION_OBJECT = {
  [USER_ACTION.CANCEL]: 'Canceled',
  [USER_ACTION.CLAIMED]: 'Claimed',
  [USER_ACTION.USER_VOTED]: 'Voted',
  [USER_ACTION.PLACED]: 'Placed',
  [USER_ACTION.UNDEFINED]: '-',
  [undefined]: '-',
  [null]: '-',
}

export const USER_BET_STATUS_OBJECT = {
  [USER_BET_STATUS.VICTORY]: 'Victory',
  [USER_BET_STATUS.DEFEAT]: 'Defeat',
  [USER_BET_STATUS.NOONEWIN]: 'No one win',
  [USER_BET_STATUS.CONSOLIDATING]: 'Waiting for result'
}