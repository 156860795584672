import React from 'react';
import { Outlet } from "react-router-dom";
import Navbar  from 'components/Navbar';
import Footer from 'components/Footer';
import { Container } from './styles';
import { SystemInfoPopup, PolicyPopup, ChooseWallet } from 'components/Popup';

export default function Layout (props) {
  return (
    <div style={{ width: '100%' }}>
      <Navbar />
      <Container>
        <SystemInfoPopup />
        <PolicyPopup />
        <ChooseWallet />
        <Outlet />
      </Container>
      <Footer />
    </div>
  );
};

Layout.propTypes = {};
Layout.defaultProps = {};