import React, { memo } from 'react';
import Box from 'components/common/Box';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';
import useTab from 'hooks/useTab';
import { TAB_OPTIONS } from 'constants/index';

export const Tab = styled.div`
  cursor: pointer;
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: ${COLOR.white};
  position: relative;
  ${(props) =>
    props.active &&
    css`
      color: ${COLOR.secondary};
      ::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        height: 2px;
        background-color: ${COLOR.secondary};
      }
    `}
`;

function Tabs(props) {
  const { tab, onChangeTab } = useTab();

  return (
    <Box fullWdith flexCenter height="32px" bgColor={COLOR.bgTabNavbar}>
      <Box flex={{ columnGap: '24px', alignItems: 'center' }}>
        {TAB_OPTIONS?.map((tabItem) => (
          <Tab
            key={tabItem.value}
            active={tab === tabItem.value}
            onClick={() => onChangeTab(tabItem.value)}
          >
            {tabItem.label}
          </Tab>
        ))}
      </Box>
    </Box>
  );
}

Tabs.propTypes = {};
Tabs.defaultProps = {};

export default memo(Tabs);
