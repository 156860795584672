import React, { memo } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import InputInfo from 'components/common/InputInfo';

function SharePopup(props) {
  const { show = false, onClose = () => {} } = props;
  return (
    <Modal
      visible={show}
      title={<Label>Share Ting<Label type='primary'>Bet</Label> in social network</Label>}
      onCancel={onClose}
      footer={null}
      width={'50%'}
    >
      <Box flex={{ direction: 'column', rowGap: '24px' }}>
        <Label size={14} block>
          Share this link to your friends. You will earn <Label type='primary'>10 TBET</Label> for first transactions of each of friends.
        </Label>
        <Box>
          <Label block mb={8} type='secondary'>03:00 3,Nov - Qatar - Ecuado: Qatar handicaps 1 1/2</Label>
         <Box mb={'55px'}><InputInfo text='https://tingbet.io?ref=1823abf23' /></Box>
          <Button onClick={onClose} fullWidth p="8px 0" type='outline' fontSize={22}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default memo(SharePopup);