import React, { memo } from 'react';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import { CopyFilled } from '@ant-design/icons';
import { useCopyToClipboard } from 'react-use';
import { message } from 'antd';
import usePlatform from 'hooks/usePlatform';

function InputInfo(props) {
  const { isLessThanTablet } = usePlatform();
  const { text = '' } = props;
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <Box
      flexSpaceBetween
      bgColor={'#1E1D20'}
      borderRadius={'20px'}
      p={isLessThanTablet ? '10px 15px' : '12px 25px'}
      color={'#fff'}
      pointer
    >
      <Label size={isLessThanTablet ? 10 : 16}>{text}</Label>
      <CopyFilled
        style={{ fontSize: isLessThanTablet ? 15 : 20 }}
        onClick={() => {
          copyToClipboard(text);
          message.success('Copied');
        }}
      />
    </Box>
  );
}

export default memo(InputInfo);
