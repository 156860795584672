import React, { memo, useCallback } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';
import COLOR from 'constants/color';
import WaitingIcon from 'components/common/Icon/WaitingIcon';
import RejectIcon from 'components/common/Icon/RejectIcon';
import useDate from 'hooks/useDate';
import { formatNumberDecimal } from 'utils/format';
import usePlatform from 'hooks/usePlatform';

function ConfirmYourBet(props) {
  const { formatDate } = useDate();
  const dispatch = useDispatch();
  const {
    confirmBetModal,
  } = useSelector((state) => ({
    confirmBetModal: state.confirmBetModal || {},
  }));
  const { isLessThanTablet } = usePlatform();
  const { 
    show = false,
    type = 'confirm',
    title = '',
    content = '',
    matchInfo = {},
    poolInfo = {},
    optionInfo = {},
    onClose = () => {},
    onContinue = () => {},
   } = confirmBetModal || {};

  const handleClose = useCallback(()=> {
    dispatch({
      type: action.TOGGLE_CONFIRM_BET_MODAL,
      payload: {
        show: false
      }
    });
    onClose && onClose();
  }, [onClose])

  if (show) {
    if (type === 'failure') {
      return (
        <Modal
          visible={show}
          title={'Failure of bet'}
          onCancel={handleClose}
          footer={null}
          width={'403px'}
        >
          <Box flex={{ direction: 'column', rowGap: '12px', alignItems: 'center' }}>
            <RejectIcon />
            <Label size={16} color={'#DB579C'}>{content || 'Transaction rejected'}</Label>
            <Button fullWidth type='secondary' p="13px" fontSize={18} onClick={handleClose}>Dismiss</Button>
          </Box>
        </Modal>
      )
    }

    if (type === 'waiting') {
      return (
        <Modal
          visible={show}
          title={title || 'Confirming your bet'}
          footer={null}
          width={'403px'}
          maskClosable={false}
        >
          <Box flex={{ direction: 'column', rowGap: '12px', alignItems: 'center' }}>
            <WaitingIcon />
            <Label size={20}>Waiting for confirmation</Label>
          </Box>
        </Modal>
      )
    }

    return (
      <Modal
        visible={show}
        title={'Confirm your bet'}
        onCancel={handleClose}
        footer={null}
        width={isLessThanTablet ? '95%' : '500px'}
      >
        <Box flex={{ direction: 'column', rowGap: '12px' }}>
          <Label size={16}>ROOM {String(poolInfo?.id || '')} </Label>
          <Label size={16}><Label size={16} type='orange'>{matchInfo?.homeTeamInfo?.[0]?.fullname?.default}</Label> (Home) - <Label size={16} type='orange'>{matchInfo?.awayTeamInfo?.[0]?.fullname?.default}</Label> (Away)</Label>
          <Label size={14}>{'Start time: ' + formatDate(matchInfo?.actualStartTime || matchInfo?.startTime, 'HH:mm dd, MMM')}</Label> 
          <Label size={16} type='secondary'><Label size={14} >Bet Type: </Label>{poolInfo?.betTypeName?.default}</Label>
          <Label size={14}>Your choise: <Label size={16} type='primary'>"{optionInfo?.name?.default}"</Label></Label>
          <Box flexSpaceBetween>
            <Label size={14}>Your staking amount</Label>
            <Label size={14} type='primary'>{formatNumberDecimal(poolInfo?.betAmount)} {poolInfo?.tokenName}</Label>
          </Box>
          <Button fullWidth type='primary' p="13px" fontSize={18} onClick={onContinue}>Confirm and continue</Button>
          <Button fullWidth type='outline' p="13px" fontSize={18} borderColor={COLOR.primary} color={COLOR.primary} onClick={handleClose}>Close</Button>
        </Box>
      </Modal>
    );
  }
  return <></>
};

export default memo(ConfirmYourBet);