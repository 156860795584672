import React, { memo, useCallback, useState, useMemo } from 'react';
import Box from 'components/common/Box';
import Label from 'components/common/Label';
import Button from 'components/common/Button';
import { LeftOutlined } from '@ant-design/icons';
import { Radio, Spin, Popconfirm } from 'antd';
import COLOR from 'constants/color';
import { SharePopup, ConfirmYourBetPopup } from 'components/Popup';
import { useNavigate, Link } from 'react-router-dom';
import { MATCH_STATUS, USER_BET_STATUS, USER_ACTION } from 'constants/status';
import usePlatform from 'hooks/usePlatform';
import useBet from 'hooks/useBet';
import { formatNumberDecimal } from 'utils/format';
import { SharePool } from 'components/Popup';

function MatchBet(props) {
  const { loading = false, data = {}, matchInfo = {} } = props;
  const [sharePopup, setSharePopup] = useState(false);
  const [sharePoolPopup, setSharePoolPopup] = useState(false);
  const { onBet, betId, setBetId, onCancel, onClaim } = useBet({
    poolInfo: data,
  });
  const navigate = useNavigate();
  const onGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  const onGoBetHistory = React.useCallback(
    (poolId) => {
      navigate(`/history/${poolId}`);
    },
    [navigate]
  );

  const onGoResult = React.useCallback(
    (poolId) => {
      navigate(`/result/${poolId}`);
    },
    [navigate]
  );
  const { isLessThanTablet } = usePlatform();

  const id = useMemo(
    () =>
      data?.id
        ? `#${String(data?.id).slice(0, 2)}...${String(data?.id).slice(-4)}`
        : '',
    [data.id]
  );

  const CLAIM_WON_FEE_RATE = process.env.REACT_APP_CLAIM_WON_FEE_RATE || 0.02;
  const CLAIM_NOONE_FEE_RATE =
    process.env.REACT_APP_CLAIM_NOONE_WIN_FEE || 0.00001;
  const TOKEN_NAME = process.env.REACT_APP_SMARTCHAIN_TOKEN || 'BNB';

  const getContentClaim = useCallback(
    (betInfo = {}) => {
      if (betInfo?.userWinLoseStatus === USER_BET_STATUS.VICTORY)
        return `Claim your victory amount (${
          Number(CLAIM_WON_FEE_RATE) * 100
        }% claim fee)`;
      if (betInfo?.userWinLoseStatus === USER_BET_STATUS.NOONEWIN)
        return `Claim your refund amount (${Number(
          CLAIM_NOONE_FEE_RATE
        )} ${TOKEN_NAME})`;
    },
    [CLAIM_WON_FEE_RATE, CLAIM_NOONE_FEE_RATE, TOKEN_NAME]
  );

  const isPlayerSelected = useMemo(
    () => Boolean(data?.optionUserSelect?.id),
    [data?.optionUserSelect?.id]
  );

  const renderScoreForDesk = (matchInfo) => (
    <Label>
      <Label type="orange">
        <img
          height={25}
          width={35}
          src={matchInfo?.homeTeamInfo?.[0]?.photo}
          alt="flag"
          style={{ margin: '-1px 10px 2px' }}
        />
        {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
      </Label>{' '}
      <Label size={20} ml={20} mr={20} mb={2}>
        {matchInfo?.resultStats?.goals?.home} -{' '}
        {matchInfo?.resultStats?.goals?.away}
      </Label>
      <Label type="orange">
        <img
          height={25}
          width={35}
          src={matchInfo?.awayTeamInfo?.[0]?.photo}
          alt="flag"
          style={{ margin: '-1px 10px 2px' }}
        />
        {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
      </Label>{' '}
    </Label>
  );
  const renderScoreForMob = (matchInfo) => (
    <Label width={'100%'} mt={10}>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.homeTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px' }}
          />
          {matchInfo?.homeTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.home
            : '-'}
        </Label>
      </Label>
      <Label width={'100%'} flexCenter>
        <Label width={'80%'} type="orange">
          <img
            height={25}
            width={35}
            src={matchInfo?.awayTeamInfo?.[0]?.photo}
            alt="flag"
            style={{ margin: '-1px 10px 2px' }}
          />
          {matchInfo?.awayTeamInfo?.[0]?.fullname?.default}
        </Label>
        <Label width={'20%'} size={20} ml={20} mr={20} mb={2} right>
          {matchInfo?.resultStats?.goals
            ? matchInfo?.resultStats?.goals?.away
            : '-'}
        </Label>
      </Label>
    </Label>
  );

  return (
    <Box
      p={isLessThanTablet ? '20px' : '35px 56px'}
      bgColor={COLOR.bgContainer}
      borderRadius="16px"
      flex={{ rowGap: '16px', direction: 'column' }}
    >
      <ConfirmYourBetPopup />
      <SharePool
        link={`${
          process.env.PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL
        }/pool/${data?.id}`}
        show={sharePoolPopup}
        onClose={() => setSharePoolPopup(false)}
      />
      {loading && (
        <Box fullWidth height={'400px'} flexCenter>
          <Spin />
        </Box>
      )}
      {!loading && data?.id && (
        <>
          <SharePopup show={sharePopup} onClose={() => setSharePopup(false)} />
          {/* Header */}
          <Box flexSpaceBetween position={{ type: 'relative' }}>
            <Box flex={{ columnGap: '14px', alignItems: 'center' }} pointer>
              <LeftOutlined className="icon-white" onClick={onGoBack} />
              {/* <Label>#{matchInfo?.id}</Label> */}
              <Label>Room:</Label>
              <Label underline pointer onClick={() => onGoBetHistory(data?.id)}>
                {id}
              </Label>
              <br />
            </Box>
            {isLessThanTablet && (
              <Label type="secondary">{matchInfo?.statusName?.default}</Label>
            )}

            <Box
              onClick={() => onGoResult(matchInfo?.id)}
              pointer
              flexCenter
              width={isLessThanTablet ? '100%' : null}
            >
              {isLessThanTablet
                ? renderScoreForMob(matchInfo)
                : renderScoreForDesk(matchInfo)}
            </Box>

            {!isLessThanTablet && (
              <Label type="secondary">{matchInfo?.statusName?.default}</Label>
            )}
          </Box>
          {/* Status */}
          {data?.userWinLoseStatus && isPlayerSelected && (
            <Button fullWidth type={data?.userWinLoseStatus} p="6px 0">
              {data?.userWinLoseStatus === 'victory' && 'VICTORY'}
              {data?.userWinLoseStatus === 'defeat' && 'DEFEAT'}
              {data?.userWinLoseStatus === 'noonewin' && 'NO ONE VICTORY'}
            </Button>
          )}
          {/* Match Info */}
          {isLessThanTablet && (
            <Box
              width={'100%'}
              borderRadius="10px"
              border={`1px solid ${COLOR.primary}`}
              p="8px 22px"
              textAlign="center"
            >
              <Label block>{data?.totalBetPlayers} Participants</Label>
              <Label type="primary">
                {formatNumberDecimal(data?.totalBetAmount || 0)}{' '}
                {data?.tokenName}
              </Label>
            </Box>
          )}
          <Box flexSpaceBetween>
            <Box>
              {/* <Label>Pool {id}</Label><br/> */}
              <Label size={isLessThanTablet ? 16 : 20}>
                Bet type:{' '}
                <Label type="secondary" size={isLessThanTablet ? 16 : 20}>
                  {data.betTypeName?.default.replace(
                    'Home ',
                    matchInfo?.homeTeamInfo?.[0]?.fullname?.default + ' '
                  )}
                </Label>{' '}
              </Label>

              <Label block size={isLessThanTablet ? 16 : 18}>
                Staking amount:{' '}
                <Label type="primary">
                  {Number(data?.betAmount)} {data?.tokenName}
                </Label>
              </Label>
              <Label
                // underline
                block
                pointer
                // type="primary"
                size={16}
                mt={2}
                onClick={() => setSharePoolPopup(true)}
              >
                <img
                  height={15}
                  width={15}
                  src="/btn-share.png"
                  alt="flag"
                  style={{ margin: '1px 3px 5px 0px' }}
                />{' '}
                Share this room
              </Label>

              {/* <InputShare text={'https://abc.com/abc'} /> */}
            </Box>
            {!isLessThanTablet && (
              <Box
                width={'35%'}
                borderRadius="10px"
                border={`1px solid ${COLOR.primary}`}
                p="8px 22px"
                textAlign="center"
              >
                <Label block>{data?.totalBetPlayers} Participants</Label>
                <Label type="primary">
                  {formatNumberDecimal(data?.totalBetAmount || 0)}{' '}
                  {data?.tokenName}
                </Label>
              </Box>
            )}
          </Box>
          <Box
            border="1px solid #5B4D81"
            m={isLessThanTablet ? '0' : '0 -56px'}
          />
          {/* Match bet Option */}
          {/* <Box><Label>Staking amount: </Label><Label type='primary'>{data?.poolSize} {data?.tokenName}</Label></Box> */}
          <Radio.Group
            onChange={(e) => setBetId(e?.target?.value)}
            value={betId}
            disabled={isPlayerSelected}
          >
            <Box
              grid={{
                columns:
                  data?.availableOptions && data?.availableOptions.length > 20
                    ? 'repeat(3, 1fr)'
                    : 'repeat(2, 1fr)',
                rowGap: '20px',
                columnGap: '4px',
              }}
            >
              {data?.availableOptions?.map((option = {}, index) => (
                <Box
                  key={option.id}
                  flex={{ alignItems: 'center', columnGap: '20px' }}
                  className={
                    Number(option?.id) === Number(data?.finalWinOptionId)
                      ? 'bet-border-selected'
                      : ''
                  }
                >
                  <Radio value={option.id} />
                  <Box flex={{ direction: 'column' }}>
                    <Box>
                      <Label>
                        {(option?.name?.default || '')
                          .replace(
                            'Home',
                            matchInfo?.homeTeamInfo?.[0]?.fullname?.default
                          )
                          .replace(
                            'Away',
                            matchInfo?.awayTeamInfo?.[0]?.fullname?.default
                          )}
                      </Label>
                    </Box>
                    <Label color={'#5FFF82'} size={14}>
                      {' '}
                      (
                      {matchInfo?.status == MATCH_STATUS.OPENING
                        ? 'possible '
                        : ''}
                      win {formatNumberDecimal(option?.possibleWin)}{' '}
                      {data?.tokenName})
                    </Label>
                    <Label>{option?.totalBetPlayers} stakers</Label>
                  </Box>
                </Box>
              ))}
            </Box>
          </Radio.Group>

          {/* Share this bet */}
          {/* <Label block underline pointer onClick={() => setSharePopup(true)}>Share this bet to earn 10 TBE</Label> */}
          {/* Divider */}
          <Box
            border="1px solid #5B4D81"
            m={isLessThanTablet ? '0' : '0 -56px'}
          />
          {/* Button Bet */}
          {matchInfo?.status === MATCH_STATUS.OPENING && isPlayerSelected && (
            // <Popconfirm title="Are you sure？" onConfirm={onCancel}>
            <Button
              fullWidth
              p="8px 0"
              type={'default'}
              fontSize={22}
              disabled={!betId}
              onClick={() => onCancel(matchInfo.poolId)}
            >
              Cancel bet
            </Button>
            // </Popconfirm>
          )}
          {matchInfo?.status === MATCH_STATUS.OPENING && !isPlayerSelected && (
            <Button
              fullWidth
              p="8px 0"
              type={'primary'}
              fontSize={22}
              onClick={() => onBet({ poolInfo: data })}
              disabled={!betId}
            >
              Confirm your bet
            </Button>
          )}
          {[USER_BET_STATUS.VICTORY, USER_BET_STATUS.NOONEWIN]?.includes(
            data?.userWinLoseStatus
          ) && (
            <Button
              fullWidth
              p="8px 0"
              type={'primary'}
              fontSize={22}
              onClick={onClaim}
              disabled={data?.userActionStatus === USER_ACTION.CLAIMED}
            >
              {data?.userActionStatus === USER_ACTION.CLAIMED
                ? 'Claimed'
                : getContentClaim(data)}
            </Button>
          )}
        </>
      )}
    </Box>
  );
}

MatchBet.propTypes = {};
MatchBet.defaultProps = {};

export default memo(MatchBet);
