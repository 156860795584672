import React, { memo, useEffect } from 'react';
import BetPoolsHistory from 'components/BetPoolsHistory';
import Box from 'components/common/Box';
import { useParams } from 'react-router-dom';
import useBetPoolsHistory from 'hooks/useBetPoolsHistory';

function BetPoolsHistoryContainer (props) {
  let params = useParams();
  const { loading, betPoolsHistory } = useBetPoolsHistory({ poolId: params.id });

  useEffect(()=> {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
   }, []);

  return (
    <Box fullWidth p={'10px 0'}>
      <BetPoolsHistory loading={loading} data={betPoolsHistory} poolId={params.id} />
    </Box>
  );
};

BetPoolsHistoryContainer.propTypes = {};
BetPoolsHistoryContainer.defaultProps = {};

export default memo(BetPoolsHistoryContainer);