// Color
const COLOR = {
  primary: '#FFD600',
  secondary: '#5FC3D1',
  orange: '#FF9B00',
  reject: '#FF3D00',
  black: '#000',
  white: '#fff',
  bgBtnDefault: '#D9D9D9',
  bgBtnDisable: '#71717B',
  bgNavbar: '#27272C',
  bgFooter: '#27262C',
  bgContainer: '#27262c80',
  bgRow: '#292939',
  bgTabNavbar: '#1E1E1E',
  bgTabItemWallet: '#302E38',
  bgTabWallet: '#1E1D20',
};

export default COLOR;