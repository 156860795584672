export const WALLET_TYPE = {
  METAMASK: {
    id: 'metamask',
    name: 'Metamask',
    icon: '/metamask_cover.png',
    ml: 8,
  },
  COINBASE: { id: 'coinbase', name: 'Coinbase', icon: '/coinbase.png', ml: 5 },
  TRUST: { id: 'trust', name: 'Trust', icon: '/trust.png', ml: 5 },
  WALLETCONNECT: {
    id: 'walletconnect',
    name: 'WalletConnect',
    icon: '/walletconnect.png',
    ml: -5,
  },
  BINANCE: { id: 'binance', name: 'Binance', icon: '/binance.png', ml: 8 },
  OPERA: { id: 'opera', name: 'Opera', icon: '/opera.png', ml: 5 },
  BRAVE: { id: 'brave', name: 'Brave', icon: '/brave.png', ml: 5 },
  INJECTED: { id: 'injected', name: 'Injected', icon: '/injected.png', ml: 8 },
  NONE: { id: 'none', name: 'none', ml: 5 },
};

export const CONNECT_WALLET_STATUS = {
  NONE: 'none',
  REJECTED: 'rejected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
};
