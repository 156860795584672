import React, { memo, useMemo } from 'react';
import { Menu, Dropdown } from 'antd';
import { ButtonIcon } from '../styles';
import { GMT_OPTIONS } from 'constants/index';
import useDate from 'hooks/useDate';

function GTMButton (props) {
  const {
    timeZone,
    onChangeTimezone
   } = useDate();
  const gmtSelected = useMemo(()=> GMT_OPTIONS?.find((item) => item.value === timeZone)?.label, [timeZone]);
  return (
    <Dropdown overlay={
      <Menu onChange={(e) => { console.warn('e', e); }}>
        {
          GMT_OPTIONS.map((item = {}) => (
            <Menu.Item key={item.value} value={item.value} onClick={() => onChangeTimezone(item.value)}>GTM {item.label}</Menu.Item>
          ))
        }
      </Menu>
    } placement="bottomLeft" arrow>
      <ButtonIcon><img src="/global.png" alt='gmt' style={{ width: '25px', height: '25px',marginRight: '8px' }} /> {' '} GMT {gmtSelected}</ButtonIcon>
    </Dropdown>
  );
};

GTMButton.propTypes = {};
GTMButton.defaultProps = {};

export default memo(GTMButton);