import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      fill="none"
      viewBox="0 0 26 24"
      {...props}
    >
      <path
        stroke="#FFD600"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.995 9v5M12.995 21.41h-6.24c-3.574 0-5.067-2.48-3.337-5.51l3.213-5.62L9.66 5c1.833-3.21 4.84-3.21 6.672 0l3.028 5.29 3.213 5.62c1.73 3.03.227 5.51-3.337 5.51h-6.24v-.01z"
      ></path>
      <path
        stroke="#FFD600"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.99 17h.009"
      ></path>
    </svg>
  );
}

export default Icon;
