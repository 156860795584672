import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';

export const ButtonCustom = styled.button`
  cursor: pointer;
  align-items: center;
  border: 0px;
  border-radius: 20px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  background-color: ${COLOR.bgBtnDefault};
  font-weight: 400;
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;

  ${props => props.variant === 'primary' && css`
    background-color: ${COLOR.primary};
    color: ${COLOR.black};
  `}
  ${props => props.variant === 'secondary' && css`
    background-color: ${COLOR.secondary};
    color: ${COLOR.black};
  `}
  ${props => props.variant === 'outline' && css`
    color: ${COLOR.secondary};
    background-color: transparent;
    border: 1px solid ${COLOR.secondary};
  `}
  ${props => props.variant === 'victory' && css`
    border: 2px solid #5FFF82;
    border-radius: 10px;
    font-weight: 400;
    font-size: 22px;
    color: #5FFF82;
    background-color: transparent;
  `}
  ${props => props.variant === 'defeat' && css`
     border: 2px solid #DB579C;
    border-radius: 10px;
    font-weight: 400;
    font-size: 22px;
    color: #DB579C;
    background-color: transparent;
  `}
  ${props => props.variant === 'noonewin' && css`
    border: 2px solid #5FC3D1;
    border-radius: 10px;
    font-weight: 400;
    font-size: 22px;
    color: #5FC3D1;
    background-color: transparent;
  `}
  ${props => props.variant === 'default' && css`
    background-color: ${COLOR.bgBtnDefault};
    color: ${COLOR.black};
  `}
  ${props => props.bgColor && css`
    background-color: ${props.bgColor};
  `}
  ${props => props.p && css`
    padding: ${props.p};
  `}
  ${props => props.width && css`
    width: ${props.width};
  `}
  ${props => props.fullWidth && css`
    width: 100%;
  `}
  ${props => props.height && css`
    height: ${props.height};
  `}
  ${props => props.borderRadius && css`
    border-radius: ${props.borderRadius};
  `}
  ${props => props.border && css`
    border: ${props.border};
  `}
  ${props => props.borderColor && css`
    border-color: ${props.borderColor};
  `}
  ${props => props.disabled && css`
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${COLOR.bgBtnDisable};
    border: 0;
  `}
  ${props => props.fontSize && css`
    font-size: ${props.fontSize}px;
  `}
  ${props => props.color && css`
    color: ${props.color};
  `}
`;

function Button (props) {
  const { type = '', htmlType = '' } = props;

  return (
    <ButtonCustom type={htmlType} variant={type}  {...props}>
      {props.children}
    </ButtonCustom>
  );
};

Button.propTypes = {};
Button.defaultProps = {};

export default memo(Button);