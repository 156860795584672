const Web3 = require('web3');
const WI = Web3.utils;
const BN = WI.BN;
const MAX_DIGITNUM = 15;

function powOf10(number_of_exp) {
  const split_nums = Math.floor(number_of_exp / MAX_DIGITNUM);
  const odd_nums = number_of_exp - split_nums * MAX_DIGITNUM;
  let final_num = new BN(1);
  for (let i = 0; i < split_nums; i++) {
    final_num = final_num.mul(new BN(10 ** MAX_DIGITNUM));
  }
  if (odd_nums > 0) {
    final_num = final_num.mul(new BN(10 ** odd_nums));
  }
  return final_num;
}

export default function toBN(number, decimals = 0) {
  const numStr = number.toString();
  if (numStr.indexOf('.') >= 0) {
    // float
    const [int, dec] = numStr.split('.');
    const dec_len = dec.length;
    const num = parseInt(`${int}${dec}`);
    return WI.toBN(num).mul(powOf10(decimals - dec_len));
  } else {
    return WI.toBN(number).mul(powOf10(decimals));
  }
}

function plusBN(num1, num2) {
  return new WI(num1).add(new WI(num2));
}

function subBN(num1, num2) {
  return new WI(num1).sub(new WI(num2));
}

function mulBN(num1, num2) {
  return new WI(num1).mul(new WI(num2));
}

function divBN(num1, num2) {
  return new WI(num1).div(new WI(num2));
}

function diff(num1, num2) {
  return new WI(num1).sub(new WI(num2));
}

function compare(num1, num2) {
  return new WI(num1).equal(new WI(num2));
}

export function divPowOf10(num, num_of_pow) {
  return toBN(num.toString().slice(0, -num_of_pow));
}

// console.log(toBN(123423.34344, 38).toString());
// console.log(toBN(2, 15).toString());
// console.log(divBN(toBN(1111111111.34344, 18),(toBN(2, 18))));
// console.log(toBN(1111111111.34344, 18).mul(toBN(2, 18)).toString());

// module.exports = {
//   toBN,
//   divPowOf10,
// };
