import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';
import useUser from 'hooks/useUser';

function useUseBets(params) {
  const dispatch = useDispatch();
  const { isLoggedIn, onLogin } = useUser();
  const {
    loading,
    userBets,
  } = useSelector((state) => ({
    loading: state.userBetsLoading,
    userBets: state.userBets,
  }));

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(action.getUserBets());
    }
  }, [isLoggedIn, dispatch]);

  const onClaim = useCallback((poolId = '') => {
    const onAction = () => {
      dispatch(action.claimFromPool(poolId, () =>  dispatch(action.getUserBets())));
    }
    if (isLoggedIn) {
      onAction();
    } else {
      onLogin(() => onAction());
    }
  }, [isLoggedIn, dispatch]);

  const onCancel = useCallback((poolId = '')=> {
    const onAction = () => {
      dispatch(action.cancelBetOption(poolId, () => dispatch(action.getUserBets())));
    }
    if (isLoggedIn) {
      onAction();
    } else {
      onLogin(() => onAction());
    }
 
  }, [isLoggedIn, dispatch]);

  return {
    loading,
    userBets,
    onClaim,
    onCancel
  }
}

export default useUseBets;