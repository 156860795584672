import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import COLOR from 'constants/color';

export const LabelCustom = styled.span`
  font-family: 'Arial Rounded MT Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: ${COLOR.white};
  ${props => props.width && css`
    width: ${props.width};
  `}
  ${props => props.mb && css`
    margin-bottom: ${props.mb}px;
  `}
  ${props => props.mr && css`
    margin-right: ${props.mr}px;
  `}
  ${props => props.mt && css`
    margin-top: ${props.mt}px;
  `}
  ${props => props.ml && css`
    margin-left: ${props.ml}px;
  `}
  ${props => props.size && css`
    font-size: ${props.size}px;
  `}
  ${props => props.weight && css`
    font-weight: ${props.weight}px;
  `}
  ${props => props.block && css`
    display: block;
  `}
  ${props => props.invisible && css`
    display: none;
  `}
  ${props => props.lineHeight && css`
    line-height: ${props.lineHeight};
  `}
  ${props => props.underline && css`
    text-decoration: underline;
    text-underline-offset: 3px;
  `}
  ${props => props.pointer && css`
    cursor: pointer;
  `}
  // Color
  ${props => props.type === 'primary' && css`
    color: ${COLOR.primary};
  `}
  ${props => props.type === 'secondary' && css`
    color: ${COLOR.secondary};
  `}
  ${props => props.type === 'black' && css`
    color: ${COLOR.black};
  `}
  ${props => props.type === 'orange' && css`
    color: ${COLOR.orange};
  `}
  ${props => props.type === 'reject' && css`
    color: ${COLOR.reject};
  `}
  ${props => props.type === 'default' && css`
    color: ${COLOR.white};
  `}
  ${props => props.color && css`
    color: ${props.color};
  `}
  ${props => props.center && css`
    text-align: center;
  `}
  ${props => props.right && css`
    text-align: right;
  `}
  ${props => props.flexCenter && css`
    display: flex;
  `}
  
`;

function Label (props) {
  const { text = '', ...rest } = props;
  return (
    <LabelCustom {...rest}>
      {props.children}
    </LabelCustom>
  );
};

export default memo(Label);