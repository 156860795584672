import React, { memo } from 'react';
import MatchList from 'components/MatchList';
import Box from 'components/common/Box';
import useMatches from 'hooks/useMatches';

function MatchListContainer (props) {
  const { 
    loading,
    matches,
    matchTab
   } = useMatches({ fetchInit: false });
  return (
    <Box fullWidth p={'10px 0'}>
      <MatchList data={matches} matchTab={matchTab} loading={loading} />
    </Box>
  );
}

MatchListContainer.propTypes = {};
MatchListContainer.defaultProps = {};

export default memo(MatchListContainer);