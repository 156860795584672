import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="138"
      fill="none"
      viewBox="0 0 130 138"
    >
      <path
        fill="#5FC3D1"
        d="M21.96 98.588c-1.018 0-2.037-.401-2.842-1.262-7.348-7.92-11.424-18.364-11.424-29.439 0-23.012 17.431-41.72 38.885-41.72l32.556.115-5.846-5.968c-1.61-1.664-1.663-4.36-.107-6.082 1.555-1.722 4.076-1.78 5.685-.115l13.087 13.428c1.18 1.205 1.555 3.099.965 4.706-.59 1.606-2.092 2.697-3.754 2.697l-42.586-.115c-17.002 0-30.84 14.863-30.84 33.111 0 8.78 3.218 17.101 9.064 23.356 1.556 1.664 1.556 4.419 0 6.083-.804.803-1.823 1.205-2.842 1.205zM54.624 124.813c-1.019 0-1.984-.401-2.789-1.205L38.748 110.18c-1.18-1.205-1.555-3.099-.965-4.705.59-1.607 2.092-2.698 3.754-2.698l42.64.115c17.002 0 30.839-14.863 30.839-33.111 0-8.78-3.218-17.101-9.064-23.356-1.555-1.664-1.555-4.419 0-6.083a3.86 3.86 0 015.685 0c7.348 7.92 11.424 18.363 11.424 29.439 0 23.011-17.431 41.719-38.884 41.719l-32.556-.115 5.846 5.968c1.609 1.664 1.662 4.362.107 6.083-.858.918-1.877 1.377-2.95 1.377z"
      ></path>
      <path
        fill="#5FC3D1"
        d="M49.26 88.948h32.181c10.298 0 18.772-9.01 18.772-20.085 0-11.018-8.42-20.085-18.772-20.085h-32.18c-10.298 0-18.772 9.01-18.772 20.085 0 11.075 8.42 20.085 18.772 20.085z"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M104.613 5.685l-4.653 5.631c-1.058 1.281-.685 2.924.821 3.65l4.641 2.259c.61.29 1.605.29 2.215 0l4.641-2.26c1.506-.737 1.879-2.38.822-3.649l-4.642-5.63c-1.045-1.294-2.787-1.294-3.845 0zM106.542 4.78v6.355"
      ></path>
      <path
        stroke="#5FC3D1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M101.316 14.941l5.226-3.806 5.226 3.806M102.523 19.436l2.003.87a5.112 5.112 0 004.044 0l2.004-.87c1.792-.773 3.447 1.33 2.202 2.804l-4.305 5.11c-1.058 1.258-2.787 1.258-3.857 0l-4.293-5.11c-1.257-1.475.398-3.577 2.202-2.804zM12.318 116.2v7.6c0 .7.412 1.4 1.03 1.7l7.208 3.9c.618.3 1.339.3 1.956 0l7.209-3.9c.618-.4 1.03-1 1.03-1.7v-7.6c0-.7-.412-1.4-1.03-1.7l-7.209-3.9a2.24 2.24 0 00-1.956 0l-7.209 3.8c-.617.4-1.03 1.1-1.03 1.8z"
      ></path>
    </svg>
  );
}

export default Icon;
