import { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

function useMatches(props) {
  const { fetchInit = true } = props || {};
  const dispatch = useDispatch();
  const {
    loading,
    matchesFilter,
    matchTab
  } = useSelector((state) => ({
    loading: state.matchesLoading || false,
    matches: state.matches || [],
    matchesFilter: state?.matchesFilter || {},
    matchTab: state?.matchTab || 'completed',
  }));

  const matches = useMemo(()=> {
    return matchesFilter[matchTab];
  }, [matchTab, matchesFilter]);

  return {
    loading,
    matches,
    matchTab,
  }
}

export default useMatches;