import React, { memo } from 'react';
import Modal from 'components/common/Modal';
import Label from 'components/common/Label';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import InputInfo from 'components/common/InputInfo';
import usePlatform from 'hooks/usePlatform';

function EarnPopup(props) {
  const { isLessThanTablet } = usePlatform();
  const { show = false, onClose = () => {} } = props;
  return (
    <Modal
      visible={show}
      title={
        <Label>
          Earn <Label type="primary">TBET</Label>
        </Label>
      }
      onCancel={onClose}
      footer={null}
      width={isLessThanTablet ? '95%' : '50%'}
    >
      <Box flex={{ direction: 'column', rowGap: '40px' }}>
        <Label size={16} block>
          To motivate users to enjoy <Label type="primary">TingBet</Label>{' '}
          platform, we give you attractive incentive: rewarding{' '}
          <Label type="primary">TBET</Label> tokens. <br />
          <Label type="primary">TBET</Label> is a governance tokens of TingBet
          project.
          <br />
          In general, you can earn <Label type="primary">TBET</Label> as a
          ratio: 1 BNB you stake for beting = 100 rewarding TBET.
          <br />
          Moreover, room creators also earn <Label type="primary">
            TBET
          </Label>{' '}
          as ratio: 1 BNB of total betting amount in your room = 10 rewarding
          TBET.
          <br />
          <br />
          You can use <Label type="primary">TBET</Label> to play games and place
          bet in Q1, 2023.
          <br />
          <Label type="primary">TingBet</Label> has a roadmap of listing{' '}
          <Label type="primary">TBET</Label> in Q2, 2023.
          <br />
          <br />
          Read more here:{' '}
          <a
            target="_blank"
            href="https://whitepaper.tingbet.io/tbet-tokens-and-tokenomic"
          >
            TBET Tokenomic
          </a>
        </Label>
        {/* <Box>
          <Label block mb={8}>Invite your friend to earn  10 <Label type='primary'>TBET</Label></Label>
         <Box mb={'55px'}><InputInfo text='https://tingbet.io?ref=1823abf23' /></Box>
          <Button onClick={onClose} fullWidth p="8px 0" type='outline' fontSize={22}>
            Close
          </Button>
        </Box> */}
      </Box>
    </Modal>
  );
}

export default memo(EarnPopup);
