import React, { useState, useMemo } from 'react';
import { LeftCol, RightCol, MenuList, MenuItem } from './styles';
import UserProfile from 'components/UserProfile';
import Tabs from './Tabs';
import Box from 'components/common/Box';
import COLOR from 'constants/color';
import { Link } from 'react-router-dom';
import {EarnPopup} from 'components/Popup';
import usePlatform from 'hooks/usePlatform';
import { useLocation } from 'react-router-dom';
import GTMButton from './GTMButton';
import { WHITE_PAPER_URL } from 'constants/index';

export default function Navbar(props) {
  const [earnPopup, setEarnPopup] = useState(false);
  const { isLessThanTablet, isGreaterThanMiniTablet } = usePlatform();
  const location = useLocation();
  const showTab = useMemo(()=> location?.pathname === '/', [location.pathname]);

  return (
    <Box
      fullWidth
      position={{ type: 'sticky', top: '0px', left: '0px', zIndex: 2 }}
      bgColor={COLOR.bgNavbar}
    >
      <EarnPopup show={earnPopup} onClose={() => setEarnPopup(false)} />
      <Box height="56px" p="0 16px" fullWidth flexSpaceBetween>
        <LeftCol>
          <Link to='/'>
            <img src="/logo-tingbet.png" alt="logo" />
          </Link>
          {
            !isLessThanTablet && (
              <>
                <MenuList style={{ marginLeft: 24 }}>
                  <MenuItem active>Bet</MenuItem>
                  <MenuItem onClick={() => setEarnPopup(true)}>Earn</MenuItem>
                  <MenuItem onClick={() => window.open(WHITE_PAPER_URL,'_blank')}>Whitepaper</MenuItem>
                </MenuList>
              </>
            )
          }
        </LeftCol>
        <RightCol>
          {
            isGreaterThanMiniTablet && (
              <>
                <GTMButton />
              </>
            )
          }
          <UserProfile />
        </RightCol>
      </Box>
      {
        showTab && (<Tabs />)
      }
    </Box>
  );
}

Navbar.propTypes = {};
Navbar.defaultProps = {};