import React, { useState, useMemo } from 'react';
import {
  Container,
  Wrapper,
  Column,
  ListIcon,
  IconWrapper,
  ContractInfo,
  ContractInfoMob,
} from './styles';
import { CopyFilled } from '@ant-design/icons';
import { useCopyToClipboard } from 'react-use';
import { message } from 'antd';
import Box from 'components/common/Box';
import usePlatform from 'hooks/usePlatform';
import Label from 'components/common/Label';
import { WHITE_PAPER_URL } from 'constants/index';
import { EarnPopup } from 'components/Popup';
import GTMButton from 'components/Navbar/GTMButton';
import COLOR from 'constants/color';

export default function Footer(props) {
  const { isLessThanTablet, isGreaterThanMiniTablet } = usePlatform();
  const [, copyToClipboard] = useCopyToClipboard();
  const [earnPopup, setEarnPopup] = useState(false);
  const SMART_CONTRACT_ID = process.env.REACT_APP_SMARTCONTRACT_ADDRESS || '';
  const MENU = [
    {
      title: 'ABOUT',
      items: [
        {
          text: 'Contact',
          link: 'https://whitepaper.tingbet.io/support/contact',
        },
        {
          text: 'Roadmap',
          link: 'https://whitepaper.tingbet.io/roadmap',
        },
        {
          text: 'Community',
          link: 'https://whitepaper.tingbet.io/support/community',
        },
        {
          text: '',
          link: '',
        },
      ],
    },
    {
      title: 'HELP',
      items: [
        {
          text: 'Term of service',
          link: 'https://whitepaper.tingbet.io/support/terms-of-service',
        },
        {
          text: 'User guide',
          link: 'https://whitepaper.tingbet.io/how-to-play',
        },
      ],
    },
    {
      title: 'DEVELOPERS',
      items: [
        {
          text: 'Github',
          link: 'https://github.com/web3-tingbet/public-smart-contract',
        },
      ],
    },
  ];

  const ICONS = [
    {
      icon: (
        <img src="/telegram.png" style={{ width: '20px', height: '20px' }} />
      ),
      link: 'https://t.me/tingbetofficial',
    },
    {
      icon: (
        <img src="/discord.png" style={{ width: '20px', height: '20px' }} />
      ),
      link: 'https://discord.gg/mjZVsAnx',
    },
    {
      icon: <img src="/github.png" style={{ width: '20px', height: '20px' }} />,
      link: 'https://github.com/web3-tingbet/public-smart-contract',
    },
  ];

  return (
    <Container>
      <EarnPopup show={earnPopup} onClose={() => setEarnPopup(false)} />
      <Wrapper style={{ paddingLeft: isLessThanTablet ? '10px' : '30px' }}>
        {isLessThanTablet && (
          <Box width={'100%'}>
            <Box>
              <Label size={16}>Choose time zone</Label>
            </Box>
            <Box
              mt={'6px'}
              mr={'10px'}
              p={'5px 5px 5px 5px'}
              borderRadius="4px"
              border={`1px solid ${COLOR.white}`}
            >
              <GTMButton />
            </Box>
            <Box mt={'15px'} onClick={() => setEarnPopup(true)} width={'100%'}>
              <Label size={18} type="primary">
                Earn TBET
              </Label>
            </Box>
            <Box
              mt={'15px'}
              onClick={() => window.open(WHITE_PAPER_URL, '_blank')}
              width={'100%'}
            >
              <Label size={18}>White paper</Label>
            </Box>
            <Label mt={'15px'} width={'100%'}>
              <ContractInfoMob>
                Contract: {SMART_CONTRACT_ID}{' '}
                <CopyFilled
                  onClick={() => {
                    copyToClipboard(SMART_CONTRACT_ID);
                    message.success('Copied');
                  }}
                />
              </ContractInfoMob>
            </Label>
          </Box>
        )}
        
        <Box
          grid={{
            columns: isLessThanTablet ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)',
          }}
        >
          {MENU.map((menuItem, index) => (
            <Column key={index}>
              <label className="title">{menuItem.title}</label>
              {menuItem.items.map((item, pos) => (
                <a href={item.link} target="_blank" className="item" key={pos}>
                  {item.text}
                </a>
              ))}
            </Column>
          ))}
        </Box>

        {!isLessThanTablet && !isGreaterThanMiniTablet && (
          <Box
            mt={'6px'}
            mr={'20px'}
            mb={'20px'}
            p={'5px 5px 5px 5px'}
            width={'92%'}
            borderRadius="4px"
            border={`1px solid ${COLOR.white}`}
          >
            <GTMButton />
          </Box>
        )}
        <IconWrapper>
          <ListIcon>
            {ICONS.map((item, index) => (
              <span key={index}>
                <a href={item.link} target="_blank" className="item">
                  {item.icon}
                </a>
              </span>
            ))}
          </ListIcon>
          {!isLessThanTablet && (
            <ContractInfo>
              Contract: {SMART_CONTRACT_ID}{' '}
              <CopyFilled
                onClick={() => {
                  copyToClipboard(SMART_CONTRACT_ID);
                  message.success('Copied');
                }}
              />
            </ContractInfo>
          )}
        </IconWrapper>
      </Wrapper>
    </Container>
  );
}

Footer.propTypes = {};
Footer.defaultProps = {};
