import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';

function useBetPoolsHistory(props) {
  const { poolId = '' } = props;
  const dispatch = useDispatch();
  const {
    loading,
    betPoolsHistory,
  } = useSelector((state) => ({
    loading: state.betPoolsHistoryLoading || false,
    betPoolsHistory: state.betPoolsHistory || [],
  }));

  useEffect(()=> {
    if (poolId) {
      dispatch(action.getBetPoolsHistory(poolId));
    }
  }, [poolId, dispatch]);

  return {
    loading,
    betPoolsHistory,
  }
}

export default useBetPoolsHistory;