import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as action from 'actions';
import useUser from './useUser';

function useBet(props) {
  const { poolInfo } = props || {};
  const [betId, setBetId] = useState('');
  const { isLoggedIn, onLogin } = useUser();
  const dispatch = useDispatch();
  const {
    matchInfo,
    poolDetail,
  } = useSelector((state) => ({
    matchInfo: state.matchSelected || {},
    poolDetail: state.poolDetail || {},
  }));

  useEffect(()=> {
    return () => {
      setBetId('');
    }
  }, []);

  useEffect(()=> {
    if (poolDetail?.optionUserSelect?.id) {
      setBetId(poolDetail?.optionUserSelect?.id);
      dispatch({
        type: action.TOGGLE_CONFIRM_BET_MODAL,
        payload: {
          show: false
        }
      })
    } else {
      setBetId('');
    }
  }, [poolDetail?.optionUserSelect?.id]);

  const onBet = useCallback(({
    poolInfo = {},
  }) => {
    const betSelected = poolInfo?.availableOptions?.find((item) => Number(item.id) === Number(betId)) || {};
    const onAction = () => {
      dispatch({
        type: action.TOGGLE_CONFIRM_BET_MODAL,
        payload: {
          show: true,
          type: 'confirm',
          matchInfo,
          poolInfo,
          optionInfo: betSelected,
          onContinue: () => {
            dispatch(action.makeBetOption(
              poolInfo?.id,
              betSelected?.id,
              parseFloat(poolInfo?.betAmount),
              () => { dispatch(action.getPoolDetail({
                poolId: poolInfo?.id,
              })) }
            ));
          }
        }
      })
    }
    if (betId) {
      if (isLoggedIn) {
        onAction();
      } else {
        onLogin(() => onAction());
      }
    }
  }, [matchInfo, isLoggedIn, betId]);

  const onCancel = useCallback(()=> {
    const onAction = () => {
      dispatch(action.cancelBetOption(poolInfo?.id, () => { 
        dispatch(action.getPoolDetail({
          poolId: poolInfo?.id,
        }))
       }));
    }
    if (isLoggedIn) {
      onAction();
    } else {
      onLogin(() => onAction());
    }
 
  }, [poolInfo?.id, isLoggedIn]);

  const onClaim = useCallback(() => {
    const onAction = () => {
      dispatch(action.claimFromPool(poolInfo?.id, () => {
        dispatch(action.getPoolDetail({
          poolId: poolInfo?.id,
        }))
      }));
    }
    if (isLoggedIn) {
      onAction();
    } else {
      onLogin(() => onAction());
    }
  }, [poolInfo?.id, isLoggedIn]);

  return {
    betId,
    setBetId,
    onBet,
    onCancel,
    onClaim,
  }
}

export default useBet;