import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, removeCookie } from 'utils/cookies';
import * as action from 'actions';

function useUser(params) {
  const dispatch = useDispatch();
  const {
    balance,
    account,
    contractAddress,
    // userEmail,
    // loading,
    tingCoin,
  } = useSelector((state) => ({
    balance: state.balance,
    account: state.account,
    contractAddress: state.contractAddress,
    // userEmail: state.userEmail,
    // loading: state.loading,
    tingCoin: state.tingCoin,
  }));

  // const userToken = getCookie("tingee-token")
  // const userEmailCookie = getCookie("tingee-user-email")
  // const currentUserEmail = userEmail || userEmailCookie

  const isLoggedIn = useMemo(
    () => contractAddress && account,
    [account, contractAddress]
  );

  const onLogout = useCallback(() => {
    dispatch(action.disconnectWallet());
  }, []);

  // const onLogin = useCallback(async (callback) => {
  //   await dispatch(action.web3Connect());
  //   callback && callback();
  // }, []);

  const onGetUserInfo = useCallback(async () => {
    await dispatch(action.getBalance());
    await dispatch(action.getTingCoin());
  }, []);

  return {
    // loading,
    // userToken,
    // currentUserEmail,
    // userEmailCookie,
    contractAddress,
    balance,
    tingCoin,
    onLogout,
    isLoggedIn,
    // onLogin,
    onGetUserInfo,
  };
}

export default useUser;
